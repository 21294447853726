
<template>
  <div style="min-height:40.5vh">
    <section
      v-if="item != null"
      id="actions"
      style="margin-top: 100px; margin-bottom: 80px"
    >
      <div>
        <div class="container">
          <card plain>
            <h3>Estado del pedido:</h3>
            <!------------------------------------------------------>
            <stepper
              :options="{
                headers: [
                  { title: 'Pedido Recibido' },
                  { title: 'Pedidio en Prepraración' },
                  { title: 'Pedido Enviado' },
                  { title: 'Pedido Entregado' },
                ],
              }"
              :currentPosition="
                item.estatus == 'RECIBIDO'
                  ? 0
                  : item.estatus == 'ENVIADO'
                  ? 2
                  : item.estatus == 'ENTREGADO'
                  ? 3
                  : 1
              "
            >
            </stepper>
            <div class="row">
              <div class="col-md-10">
                <b-table
                  show-empty
                  small
                  stacked="md"
                  :items="item.carrito"
                  :fields="[
                    {
                      key: 'cantidad',
                      label: 'Cantidad',
                      sortable: true,
                      sortDirection: 'desc',
                    },
                    {
                      key: 'model',
                      label: 'Modelo',
                      sortable: true,
                      sortDirection: 'desc',
                    },
                    {
                      key: 'title',
                      label: 'Producto',
                      sortable: true,
                      sortDirection: 'desc',
                    },
                    {
                      key: 'precio',
                      label: 'Precio Unit',
                      sortable: true,
                      sortDirection: 'desc',
                    },
                    {
                      key: 'importe',
                      label: 'Importe',
                      sortable: true,
                      sortDirection: 'desc',
                    },
                  ]"
                  empty-text="Lista de árticulos no disponible"
                >
                  <template v-slot:cell(precio)="article">
                    {{ $custom.Formatter.format(article.item.price) }}
                  </template>
                  <template v-slot:cell(importe)="article">
                    {{ $custom.Formatter.format(article.item.total) }}
                  </template>
                </b-table>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Subtotal: </label>
                      {{ $custom.Formatter.format(item.subtotal) }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Envio: </label>
                      {{ $custom.Formatter.format(item.envio) }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Total: </label>
                      {{ $custom.Formatter.format(item.total) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-md-6">
                <h4 class="mb-1">Domicilio de entrega:</h4>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">CP: </label>
                      {{ item.dir_envio.cp }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Estado: </label>
                      {{ item.dir_envio.estado }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Ciudad: </label>
                      {{ item.dir_envio.ciudad }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Colonia: </label>
                      {{ item.dir_envio.colonia }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Dirección: </label>
                      {{ item.dir_envio.calle }} #{{item.dir_envio.no_ext}} {{item.dir_envio.no_int?'Int '+item.dir_envio.no_int:''}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="item.bill">
                <h4 class="mb-1">Facturación:</h4>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">RFC: </label>
                      {{ item.factura.RFC }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">RZON SOCIAL: </label>
                      {{ item.factura.Razon }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">USO CFDI: </label>
                      {{ item.factura.CFDI }}
                    </p>
                  </div>
                </div>


               
              </div>
            </div>
          </card>
          <!------------------------------------------------------>
        </div>
      </div>
    </section>
    <div v-else>
      <div v-if="!loading">
        <div class="d-flex justify-content-center my-3">
          <p class="h1 mb-2">
            <b-icon
              icon="exclamation-triangle-fill"
              style="width: 150px; height: 150px"
              variant="warning"
              aria-hidden="true"
            ></b-icon>
          </p>
        </div>
        <div class="d-flex justify-content-center my-3">
          <h1 class="primary--text text--primary">Pedido no encontrado</h1>
        </div>
        <div class="d-flex justify-content-center my-3">
          <n-button type="primary" class="mt-0" outline round>
            Seguir comprando
          </n-button>
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center my-3">
          <b-spinner
            variant="primary"
            style="width: 15rem; height: 15rem"
            label="Large Spinner"
            type="grow"
          ></b-spinner>
        </div>
        <div class="d-flex justify-content-center my-3">
          <h1 class="primary--text text--primary">Procesando</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Pagination, Card } from "@/components";

import { BTable, BPagination } from "bootstrap-vue";
import Stepper from "../../components/Stepper/Stepper";

export default {
  name: "profile",
  bodyClass: "profile-page",
  components: {
    BPagination,
    BTable,
    Card,
    [Pagination.name]: Pagination,
    [Button.name]: Button,
    Stepper,
  },
  async created() {
    this.item = null;
    this.loading = true;
    try {
      let id = atob(this.$route.params.id);
      let response = await this.$api.get("/pedidos", {
        params: { id: id },
        headers: { method: "seguimiento" },
      });

      if (response.data.success) {
        this.item = response.data.data;
        this.loading = false;
      } else {
        this.item = null;
        this.loading = false;
      }
    } catch (error) {
      this.item = null;
      this.loading = false;
    }
  },
  data: () => {
    return {
      loading: false,
      item: null,
    };
  },
  methods: {
  },
};
</script>
<style scoped>
</style>