<template>
  <div>
    <div v-if="showStatics" style="min-height: 35vh">
      <!-- Validando TransactionToken-->
      <div v-if="status.validating">
        <div class="d-flex justify-content-center my-5">
          <b-spinner
            variant="primary"
            style="width: 15rem; height: 15rem"
            label="Large Spinner"
            type="grow"
          ></b-spinner>
        </div>
        <div class="d-flex justify-content-center my-3">
          <h1 class="primary--text text--primary">Procesando</h1>
        </div>
      </div>
      <!-- Pago exitoso -->
      <div v-if="status.success">
        <div class="d-flex justify-content-center my-5">
          <p class="h1 mb-2">
            <b-icon
              icon="check2-all"
              style="width: 150px; height: 150px"
              variant="success"
              aria-hidden="true"
            ></b-icon>
          </p>
        </div>
        <div class="d-flex justify-content-center my-3">
          <h1 class="primary--text text--primary">Pago exitoso</h1>
        </div>
        <div class="d-flex justify-content-center my-3">
          <n-button
            v-if="profile"
            type="primary"
            class="mt-0"
            outline
            round
            @click.native="goToOrders"
          >
            Ver mis pedidos
          </n-button>
          <n-button
            v-else
            type="primary"
            class="mt-0"
            outline
            round
            @click.native="goToProducts"
          >
            Continuar comprando
          </n-button>
        </div>
      </div>
      <!-- Pago en espera -->
      <div v-if="status.wait_threeds">
        <div class="d-flex justify-content-center my-5">
          <p class="h1 mb-2">
            <b-icon
              icon="clock-history"
              style="width: 150px; height: 150px"
              variant="info"
              aria-hidden="true"
            ></b-icon>
          </p>
        </div>
        <div class="d-flex justify-content-center my-3">
          <h1 class="primary--text text--primary">
            No has completado la validación
          </h1>
        </div>
        <div class="d-flex justify-content-center my-3">
          <n-button
            type="default"
            class="mt-0 mr-3"
            outline
            round
            @click.native="goToResume"
          >
            Probar otro metodo de pago
          </n-button>
          <n-button
            type="primary"
            class="mt-0 ml-3"
            outline
            round
            @click.native="reload"
          >
            Recargar página
          </n-button>
        </div>
      </div>
      <!-- Pago declinado -->
      <div v-if="status.failed">
        <div class="d-flex justify-content-center my-5">
          <p class="h1 mb-2">
            <b-icon
              icon="exclamation-triangle-fill"
              style="width: 150px; height: 150px"
              variant="warning"
              aria-hidden="true"
            ></b-icon>
          </p>
        </div>
        <div class="d-flex justify-content-center my-3">
          <h1 class="primary--text text--primary">Pago declinado</h1>
        </div>
        <div class="d-flex justify-content-center my-3">
          <n-button
            type="primary"
            class="mt-0"
            outline
            round
            @click.native="goToResume"
          >
            Probar otro metodo de pago
          </n-button>
        </div>
      </div>
      <!-- Error de conexión -->
      <div v-if="status.error">
        <div class="d-flex justify-content-center my-5">
          <p class="h1 mb-2">
            <b-icon
              icon="wifi-off"
              style="width: 150px; height: 150px"
              variant="warning"
              aria-hidden="true"
            ></b-icon>
          </p>
        </div>
        <div class="d-flex justify-content-center my-3">
          <h1 class="primary--text text--primary">
            Verifica tu conexión y recarga por favor
          </h1>
        </div>
        <div class="d-flex justify-content-center my-3">
          <n-button
            type="primary"
            class="mt-0"
            outline
            round
            @click.native="reload"
          >
            Recargar
          </n-button>
        </div>
      </div>
    </div>

    <div v-if="!showStatics">
      <div class="container">
        <div class="row mt-5">
          <div class="offset-md-4 col-md-4 mt-5 pr-2">
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  ref="cardNumber"
                  v-model="card.cardNumber"
                  placeholder="#### #### #### ####"
                  v-mask="numberCard"
                  label="Número de tarjeta"
                  required
                  @change="validar"
                ></fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <fg-input
                  ref="vigencia"
                  v-model="card.exp"
                  placeholder="## / ####"
                  v-mask="vigencia"
                  label="Vigencia"
                  required
                  @change="validar"
                ></fg-input>
              </div>

              <div class="col-md-6">
                <fg-input
                  ref="cvv"
                  v-mask="cvv"
                  v-model="card.cvv"
                  placeholder="###"
                  label="CVV/CVV2"
                  type="password"
                  required
                  @change="validar"
                ></fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  v-model="card.firstName"
                  ref="firstName"
                  label="Nombre(s)"
                  required
                  @change="validar"
                  @keypress="forceKeyPressUppercase($event, 'firstName')"
                ></fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <fg-input
                  ref="lastName"
                  v-model="card.lastName"
                  label="Apellidos"
                  required
                  @change="validar"
                  @keypress="forceKeyPressUppercase($event, 'lastName')"
                ></fg-input>
              </div>
            </div>

            <!--<div class="row">
              <fg-input
                class="col-md-12"
                ref="phone"
                v-mask="telefono"
                v-model="card.phone"
                required
                @change="validar"
                label="Teléfono de contacto"
              ></fg-input>
            </div>-->
            <div class="row text-danger">{{ errorCard }}</div>
          </div>
          <!-- *************************************************************** -->

          <!--div class="col-md-4 mt-5 pr-2">
            <div class="row">
              <fg-input
                class="col-md-12"
                ref="cp"
                v-model="card.address.cp"
                type="number"
                label="Código Postal"
                @change="
                  buscarCP();
                  validar();
                "
                required
              ></fg-input>
            </div>
            <div class="row">
              <fg-select
                ref="colonia"
                class="col-md-12"
                v-model="card.address.colonia"
                label="Colonia"
                :options="colonias"
                required
                @change="validar"
              ></fg-select>
            </div>

            <div class="row">
              <fg-input
                class="col-md-12"
                ref="calle"
                v-model="card.address.calle"
                label="Calle"
                required
                @change="validar"
              ></fg-input>
            </div>
            <div class="row">
              <fg-input
                class="col-md-6"
                ref="ext"
                v-model="card.address.ext"
                type="number"
                label="Número exterior"
                required
                @change="validar"
              ></fg-input>

              <fg-input
                class="col-md-6"
                ref="int"
                v-model="card.address.int"
                type="text"
                label="Número interior"
                required
                @change="validar"
              ></fg-input>
            </div>

            <div class="row">
              <fg-input
                class="col-md-12"
                ref="email"
                type="email"
                v-model="card.email"
                label="Email"
              ></fg-input>
            </div>

            <div class="row text-danger">{{ errorAddress }}</div>
          </div>-->

          <!-- *************************************************************** -->
          <div class="col-md-4">
            <card class="mt-5" style="background-color: rgb(163, 161, 158)">
              <div class="container">
                <h4 slot="header" class="title title-up mt-1 pt-1">Resumen</h4>
                <div class="row">
                  <avatar
                    v-for="(item, i) in shoppingCart"
                    :key="i"
                    :src="
                      item.images == 'url' ? noimage : item.images.split(',')[0]
                    "
                  ></avatar>
                </div>
              </div>
              <div class="card-text mt-5">
                <div class="row px-1">
                  <div class="col-md-6 text-primary"><h5>Subtotal</h5></div>
                  <div class="col-md-6 text-right text-primary">
                    <h5>{{ this.$custom.Formatter.format(subtotal) }}</h5>
                  </div>
                </div>

                <!-- <div class="row px-1">
                  <div class="col-md-6 text-white"><h5>Promociones</h5></div>
                  <div class="col-md-6 text-right text-white">
                    <h5>-{{ this.$custom.Formatter.format(promocion) }}</h5>
                  </div>
                </div>
                <div class="row px-1">
                  <div class="col-md-6 text-white"><h5>Descuentos</h5></div>
                  <div class="col-md-6 text-right text-white">
                    <h5>-{{ this.$custom.Formatter.format(descuento) }}</h5>
                  </div>
                </div>
                <div class="row px-1">
                  <div class="col-md-6 text-primary"><h5>IVA</h5></div>
                  <div class="col-md-6 text-right text-primary">
                    <h5>{{ this.$custom.Formatter.format(iva) }}</h5>
                  </div>
                </div>-->
                <div class="row px-1">
                  <div class="col-md-6 text-white"><h5>Envio</h5></div>
                  <div class="col-md-6 text-right text-white">
                    <h5>{{ this.$custom.Formatter.format(envio) }}</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label
                      class="mt-0 text-white"
                      :style="!canGoResume ? 'display:inline' : 'display:none'"
                    >
                      Calculando envio
                    </label>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6 text-primary"><h4>Total</h4></div>
                  <div class="col-md-6 text-primary text-right">
                    <h4>{{ this.$custom.Formatter.format(totalfinal) }}</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 text-white">
                    <h4>{{ options }} pago(s) X</h4>
                  </div>
                  <div class="col-md-6 text-white text-right">
                    <h4>{{ sobretasa(options) }}</h4>
                  </div>
                </div>
                <div class="row mr-1 my-3">
                  <div class="col-md-6 text-white">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="facturar"
                      name="checkbox-1"
                      value="true"
                      unchecked-value="false"
                      disabled
                    >
                      Facturar
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6 text-primary text-right">
                    <n-button
                      type="primary"
                      class="mt-0"
                      outline
                      round
                      @click.native="goToPay"
                      :disabled="!canGoResume"
                    >
                      {{ canGoResume ? "Continuar" : "Calculando envio" }}
                    </n-button>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Review-->
    <modal
      :show.sync="modals.review"
      :show-close="false"
      header-classes="justify-content-center"
    >
      <h4 slot="header" class="title title-up">Procesando pago</h4>
      <h4>
        Tu banco validará tu compra enviando un código por SMS, enseguida serás
        redirigido a la pantalla de validación.
      </h4>
    </modal>

    <Spiner :show="modals.loading" :msg="'Cargando...'" />
  </div>
</template>

<script>
const PUBLIC_PATH = process.env.BASE_URL;
import { mask } from "../../components/Mask";
import {
  Button,
  FormGroupInput,
  FormGroupSelect,
  FormGroupSelect as FgSelect,
  Card,
  Modal,
  Checkbox,
} from "@/components";

import Avatar from "vue-avatar";
import Spiner from "../../components/Spiner/Spiner";

export default {
  name: "paymet-netpay",
  bodyClass: "payment-netpay-page",
  components: {
    Avatar,
    Card,
    Checkbox,
    FgSelect,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [FormGroupSelect.name]: FormGroupSelect,
    [Modal.name]: Modal,
    Spiner,
  },
  directives: {
    mask,
  },
  data() {
    return {
      telefono: "###-###-####",
      numberCard: ["#### ###### #####", "#### #### #### ####"],
      vigencia: "## / ####",
      cvv: ["###", "####"],
      domicilio: {
        cp: "",
        estado: "",
        municipio: "",
        ciudad: "",
        colonia: "",
        calle: "",
        int: "",
        ext: "",
      },
      card: {
        address: {},
        cardNumber: "",
        exp: null,
        cvv: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      },
      /*{
        address: {},
        cardNumber: "4000000000000002",
        exp: null,
        cvv: "123",
        firstName: "JUAN",
        lastName: "PEREZ"
      },*/
      colonias: [],

      modals: {
        review: false,
        loading: false,
      },
      showStatics: false,
      status: {
        validating: false,
        success: false,
        failed: false,
        wait_threeds: false,
        error: false,
      },
      shoppingCart: [],
      promotions: [],
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      profile: null,
      facturar: false,
      subtotal: 0,
      iva: 0,
      promocion: 0,
      descuento: 0,
      envio: 0,
      totalfinal: 0,
      options: "3",
      tasa: 0.035,

      ivasobretasa: 0.16,
      sobretasas: {
        1: 0,
        3: 0.0466,
        6: 0.0751,
        9: 0.1086,
        12: 0.1363,
        18: 0.1999,
      },
      canGoResume: false,
      errorCard: null,
      errorAddress: null,
    };
  },
  async created() {
    this.profile = this.$decodeToken();
    this.shoppingCart = this.$store.getters.getCarrito();

    let msi = this.$store.getters.getMSI();

    if (this.shoppingCart.length > 0) {
      if (msi == "coppel") {
        this.$router.push({ name: "coppel" });
      } else {
        this.domicilio = this.$store.getters.getDomicilio();
        if (this.domicilio != null) {
          this.card.address = Object.assign({}, this.domicilio);
          this.buscarCP();

          this.envio = this.$store.getters.getEnvio();

          if (this.envio > 0) {
            this.canGoResume = true;
            this.facturacion = this.$store.getters.getFacturacion();

            if (this.facturacion != null) {
              this.facturar = true;
            }
            this.getPromotions();

            this.getResume();

            this.options = msi;
            //////////////////////////////////
            this.getResume();

            this.card.exp = "12 / " + (new Date().getFullYear() + 1);

            if (this.$route.query.transaction_token) {
              this.showStatics = true;
              this.setStatus("validating");
              this.validateStatusPayment();
            }
          } else {
            this.$router.push({ name: "envio" });
          }
        } else {
          this.$router.push({ name: "envio" });
        }
      }
    } else {
      this.$router.push({ name: "carrito" });
    }
  },
  mounted() {
    if (!this.$route.query.transaction_token) {
    }
  },
  destroyed() {},
  methods: {
    async goToPay() {
      let isValid = await this.validar();

      if (isValid) {
        this.showModals("loading");
        this.errorCard = "  ";
        this.errorAddress = " ";
        /** Pruebas de pago */
        //NetPay.setApiKey("pk_netpay_tDNHgzjIxpXLXxAKMztYNSKVO");
        //NetPay.setSandboxMode(true);

        /** Pagos de verdad */
        NetPay.setApiKey("pk_netpay_sPICBpbEOKlmXLjmZhGnbgFHs");
        NetPay.setSandboxMode(false);

        let cardInformation = {
          cardNumber: this.card.cardNumber.replaceAll(" ", ""),
          expMonth: this.card.exp.split(" / ")[0],
          expYear: this.card.exp.split(" / ")[1],
          cvv2: this.card.cvv,
          cardHolderName:
            this.card.firstName +
            (this.card.lastName ? " " + this.card.lastName : ""),
        };

        NetPay.token.create(
          cardInformation,
          this.requestPayment,
          this.errorToken
        );
      }
    },
    async requestPayment(e) {
      let data = JSON.parse(e.message.data);
      let deviceFingerPrint = e.message.deviceFingerPrint;

      try {
        let response = await this.$api.post(
          "/payment/request",
          {
            deviceFingerPrint: deviceFingerPrint,
            card: {
              bank: data.bank,
              brand: data.brand,
              cardPrefix: data.cardPrefix,
              country: data.country,
              lastFourDigits: data.lastFourDigits,
              scheme: data.scheme,
              token: data.token,
              type: data.type,
              firstName: this.card.firstName,
              lastName: this.card.lastName,
              //email: this.card.email,
              //phone: this.card.phone.replaceAll("-", ""),
              //address: this.card.address,
            },
            amount: this.totalfinal,
            msi: this.options,
            price: this.profile ? this.profile.precio : null,
            resume: {
              subtotal: this.subtotal,
              promocion: this.promocion,
              descuento: this.descuento,
              iva: this.iva,
              envio: this.envio,
              total: this.totalfinal,
            },

            ship: this.$store.getters.getDomicilio(),
            shippingMethod: "Paqueteria",

            shoppingCart: this.$store.getters.getCarrito(),
            billing: this.$store.getters.getFacturacion(),

            client: this.profile
              ? this.profile
              : this.$store.getters.getInvitado(),
          },
          { headers: { method: "netpay" } }
        );
        ///////////////////////////////////////
        switch (response.data.status) {
          case "success":
            this.showStatics = true;
            this.setStatus("success");
            this.$store.commit("setCarrito", []);
            this.$store.commit("setDomicilio", null);
            this.$store.commit("setEnvio", null);
            this.$store.commit("setFacturacion", null);
            this.$store.commit("setInvitado", null);
            this.$store.commit("setMSI", null);
            this.$store.commit("setTransaction", null);
            break;
          case "failed":
            this.showStatics = true;
            this.setStatus("failed");
            break;
          case "review":
            this.$store.commit("setTransaction", response.data.transaction);
            this.showModals("review");
            this.redirectTo(response.data.redirectTo);
            break;
          default:
            this.showStatics = true;
            this.setStatus("error");
            break;
        }
        this.modals.loading = false;
      } catch (error) {
        //console.log(error);
        this.modals.loading = false;
        this.errorAddress = "Revisa tu conexión e intenta nuevamente";
        this.resetForm();
      }
    },
    async validateStatusPayment() {
      try {
        let response = await this.$api.post(
          "/payment/validate",
          {
            transaction_token: this.$route.query.transaction_token,
            transaction: this.$store.getters.getTransaction(),
            amount: this.totalfinal,
            msi: this.options,
            price: this.profile ? this.profile.precio : null,
            resume: {
              subtotal: this.subtotal,
              promocion: this.promocion,
              descuento: this.descuento,
              iva: this.iva,
              envio: this.envio,
              total: this.totalfinal,
            },

            ship: this.$store.getters.getDomicilio(),
            shippingMethod: "Paqueteria",

            shoppingCart: this.$store.getters.getCarrito(),
            billing: this.$store.getters.getFacturacion(),

            client: this.profile
              ? this.profile
              : this.$store.getters.getInvitado(),
          },
          { headers: { method: "netpay" } }
        );

        ///////////////////////////////////////
        switch (response.data.status) {
          case "success":
            this.setStatus("success");
            this.$store.commit("setCarrito", []);
            this.$store.commit("setDomicilio", null);
            this.$store.commit("setEnvio", null);
            this.$store.commit("setFacturacion", null);
            this.$store.commit("setInvitado", null);
            this.$store.commit("setMSI", null);
            this.$store.commit("setTransaction", null);
            break;
          case "failed":
            this.setStatus("failed");
            break;
          case "wait":
            this.setStatus("wait_threeds");
            break;
          default:
            this.setStatus("error");
            break;
        }
      } catch (error) {
        //console.log(error);
        this.setStatus("error");
      }
    },
    errorToken(e) {
      //console.log("error en tokencard");
      //console.log(e);
      this.modals.loading = false;
      switch (e.message) {
        case "Empty or invalid cvv":
          this.errorCard = "CVV Invalido";
          break;
        case "Empty or invalid card number":
          this.errorCard = "Número de tarjeta invalido";
          break;
        default:
          this.errorCard = "Verifica los datos";
          break;
      }
      this.resetForm();
    },
    /////////////////////////////////////////////////////////////
    showModals(modal) {
      this.modals[modal] = true;
    },
    setStatus(status) {
      this.status = {
        validating: false,
        success: false,
        failed: false,
        wait_threeds: false,
        error: false,
      };
      this.status[status] = true;
    },
    /////////////////////////////////////////////////////////////
    goToOrders() {
      this.$router.push({ name: "pedidos" });
    },
    goToResume() {
      this.$router.push({ name: "resume" });
    },
    goToProducts() {
      this.$router.push({ name: "productos" });
    },
    reload() {
      this.$router.go(this.$router.currentRoute);
    },
    redirectTo(url) {
      setTimeout(() => {
        window.location = url;
      }, 3000);
    },
    resetForm() {
      this.card = {
        address: {},
        cardNumber: null,
        exp: null,
        cvv2: null,
        cardHolderName: null,
      };
    },
    /////////////////////////////////////////////////////////////
    getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.shoppingCart[n].price * this.shoppingCart[n].cantidad;
      }

      this.totalfinal = this.subtotal + this.envio;
    },
    /*getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.$custom.getHigherPrice(this.shoppingCart[n]) *
          this.shoppingCart[n].cantidad;
        // Si tiene promocion
        if (
          this.promotions.filter((p) => p.codigo == this.shoppingCart[n].codigo)
            .length > 0
        ) {
          //Si es descuento por precio
          if (
            this.promotions.filter(
              (p) => p.codigo == this.shoppingCart[n].codigo
            )[0].precio > 0
          ) {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
          //si es descuento por porcentaje
          else {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
                this.shoppingCart[n].cantidad;

            this.iva +=
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
        //no tiene promocion
        else {
          if (this.profile) {
            this.descuento +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.shoppingCart[n][this.profile.precio] *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.shoppingCart[n][this.profile.precio] *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          } else {
            this.iva +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
      }

      this.totalfinal =
        this.subtotal - this.promocion - this.descuento + this.iva + this.envio;
    },*/
    sobretasa(msi) {
      let cobrar =
        this.totalfinal /
        (1 - (this.sobretasas[msi] * this.ivasobretasa + this.sobretasas[msi]));
      return this.$custom.Formatter.format((cobrar / msi).toFixed(2));
    },
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },
    async buscarCP() {
      this.colonias = [];
      if (this.card.address.cp) {
        try {
          let response = await this.$cpostal.get(
            this.card.address.cp + "?token=928fbdb3-1f6d-4c76-93ab-d87a415bcddf"
          );

          let result = response.data;

          result.forEach((element) => {
            this.colonias.push({
              text: element.response.asentamiento.toUpperCase(),
              value: element.response.asentamiento.toUpperCase(),
              asentamiento: element.response.asentamiento,
              ciudad:
                element.response.ciudad == ""
                  ? element.response.municipio
                  : element.response.ciudad,
              cp: element.response.cp,
              estado: element.response.estado,
              municipio: element.response.municipio,
              pais: element.response.pais,
              tipo_asentamiento: element.response.tipo_asentamiento,
            });
          });

          if (this.colonias.length > 0) {
            this.card.address.estado = this.colonias[0].estado;
            this.card.address.ciudad = this.colonias[0].ciudad;
            this.card.address.municipio = this.colonias[0].municipio;
          } else {
            this.card.address.estado = null;
            this.card.address.ciudad = null;
            this.card.address.municipio = null;
          }
        } catch (error) {
          this.colonias = [];
          this.card.address.estado = null;
          this.card.address.ciudad = null;
          this.card.address.municipio = null;
        }
      }
    },
    /////////////////////////////////////////////////////////////
    async validar() {
      if (
        this.card.cardNumber == null ||
        this.card.cardNumber.length < 15 ||
        this.card.cardNumber.length > 19
      ) {
        this.setValidate("cardNumber", false);
        this.errorCard = " Ingresa tu número de tarjeta";
        return false;
      } else if (
        this.card.exp.split(" / ")[0] > 12 ||
        this.card.exp.split(" / ")[0] < 1
      ) {
        this.setValidate("vigencia", false);
        this.errorCard = " La vigencia ingresada no es valida ";
        return false;
      } else if (
        this.card.exp.split(" / ")[1] < new Date().getFullYear() ||
        (Number(this.card.exp.split(" / ")[0]) <= new Date().getMonth() + 1 &&
          this.card.exp.split(" / ")[1] == new Date().getFullYear())
      ) {
        this.setValidate("vigencia", false);
        this.errorCard = " La vigencia ingresada no es valida ";
        return false;
      } else if (
        typeof this.card.cvv == "undefined" ||
        this.card.cvv == null ||
        this.card.cvv == "" ||
        this.card.cvv.length < 3
      ) {
        this.setValidate("cvv", false);
        this.errorCard = " Tu cvv debe tener de 3 a 4 números ";
        return false;
      } else if (
        typeof this.card.firstName == "undefined" ||
        this.card.firstName == null ||
        this.card.firstName == "" ||
        this.card.firstName.length < 1
      ) {
        this.setValidate("firstName", false);
        this.errorCard = " Ingresa el nombre del titular de la tarjeta";
        return false;
      } else if (
        typeof this.card.lastName == "undefined" ||
        this.card.lastName == null ||
        this.card.lastName == "" ||
        this.card.lastName.length < 1
      ) {
        this.setValidate("lastName", false);
        this.errorCard = " Ingresa el apellido del titular de la tarjeta";
        return false;
      } /*else if (
        typeof this.card.phone == "undefined" ||
        this.card.phone == null ||
        this.card.phone == "" ||
        this.card.phone.length < 12
      ) {
        this.setValidate("phone", false);
        this.errorCard = " Ingresa tu número de teléfono";
        return false;
      }*/
      ////////////////////////////////////////////////////////////
      /*else if (
        this.card.address.cp == "undefined" ||
        this.card.address.cp == null ||
        this.card.address.cp == "" ||
        this.card.address.cp.length < 5
      ) {
        this.setValidate("cp", false);
        this.errorAddress = " Ingresa tu codigo postal";
        return false;
      } else if (
        this.card.address.colonia == "undefined" ||
        this.card.address.colonia == null ||
        this.card.address.colonia == "" ||
        this.card.address.colonia.length < 1
      ) {
        this.setValidate("colonia", false);
        this.errorAddress = " Selecciona tu colonia";
        return false;
      } else if (
        this.card.address.calle == "undefined" ||
        this.card.address.calle == null ||
        this.card.address.calle == "" ||
        this.card.address.calle.length < 1
      ) {
        this.setValidate("calle", false);
        this.errorAddress = " Ingresa tu calle";
        return false;
      } else if (
        this.card.address.ext == "undefined" ||
        this.card.address.ext == null ||
        this.card.address.ext == "" ||
        this.card.address.ext.length < 1
      ) {
        this.setValidate("ext", false);
        this.errorAddress = " Ingresa número exterior";
        return false;
      } else if (
        this.card.email == "undefined" ||
        this.card.email == null ||
        this.card.email == "" ||
        !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.card.email)
      ) {
        this.setValidate("email", false);
        this.errorAddress = " Ingresa tu email";
        return false;
      }*/

      ////////////////////////////////////////////////////////////
      else {
        this.errorCard = " ";
        this.errorAddress = "";
        return true;
      }
    },
    setValidate(ref, success) {
      if (this.$refs[ref]) {
        if (success) {
          this.$refs[ref].$el.classList.add("has-success");
          this.$refs[ref].$el.classList.remove("has-danger");
        } else {
          this.$refs[ref].$el.classList.remove("has-success");
          this.$refs[ref].$el.classList.add("has-danger");
        }
      }
    },
    forceKeyPressUppercase(e, refer) {
      var charInput = e.keyCode;
      if (charInput >= 97 && charInput <= 122) {
        // lowercase
        if (!e.ctrlKey && !e.metaKey && !e.altKey) {
          // no modifier key
          var newChar = charInput - 32;
          var start = e.target.selectionStart;
          var end = e.target.selectionEnd;
          e.target.value =
            e.target.value.substring(0, start) +
            String.fromCharCode(newChar) +
            e.target.value.substring(end);
          e.target.setSelectionRange(start + 1, start + 1);
          e.preventDefault();
          this.card[refer] = e.target.value;
        }
      }
    },
  },
  computed: {},
};
</script>

