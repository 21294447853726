 <template>
  <div>

      <Carousel />
   
      <Mostselled  />

      <Categories  />

  </div>
</template>
<script>
import Carousel from "./Carousel";
import Mostselled from "./Mostselled";
import Categories from "./Categories";

const PUBLIC_PATH = process.env.BASE_URL;

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Carousel,
    Mostselled,
    Categories,
  },
  data() {
    return {
      background: `${PUBLIC_PATH}background/background_1.png`,
      logo: `${PUBLIC_PATH}brand/logo_conti.png`,
      publicPath: PUBLIC_PATH,
    };
  },
};
</script>

