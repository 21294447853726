<template>
  <div>
    <gmap-map
      ref="gmap"
      :center="center"
      :zoom="15"
      style="width: 100%; height: 70vh"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="toggleInfoWindow(m, index)"
      >
      </gmap-marker>

      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAfrbNIdUzLpsaKr5_PIqeURaTNT-qonL4", // SENSA
 
    //key: "AIzaSyDp3Ud3SixagW8bJOwtosy5bBMB5JRNS_k", // De prueba
    mode: "place",
  },
});

export default {
  name: "GoogleMap",
  data() {
    return {
      //a default center for the map 27.475830, -99.511112    27.475788, -99.511033
      //center: {lat: 52.511950, lng: 6.089625},

      center: { lat: 19.688474417449346, lng: -101.14603686076367},
      map: null,
      infoContent: "ABV Electric Supply",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      markers: [
        {
          name: "ABV Electric Supply",
          description:
            "Calle Fresno 210, Jardines del Rincón, 58270 Morelia, Mich.",
          date_build: "", 
          position: { lat: 19.688474417449346, lng: -101.14603686076367 },
        }
      ],
    };
  },
  mounted() {
    //set bounds of the map
    this.$refs.gmap.$mapPromise.then((map) => {
      const bounds = new google.maps.LatLngBounds();
      for (let m of this.markers) {
        bounds.extend(m.position);
      }
      //map.fitBounds(bounds);
    });
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getInfoWindowContent: function (marker) {
      return `<div class="card" style="color: black; width: 14vw;">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="brand/logo_conti.png" alt="Conti Papelerías" style="height: 40px;">
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4" style="padding-top: 0px;">${marker.name}</p>
                </div>
              </div>
              <div class="content">
                ${marker.description}
                <br>
                <time datetime="2016-1-1">${marker.date_build}</time>
              </div>
            </div>
          </div>`;
    },
  },
};
</script>