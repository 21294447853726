<template>
  <div style="background-color: rgba(163, 161, 158, 0.1)">
    <div class="container">
      <slideshow
        :images="images"
        :slidesInterval="4000"
        :showNoImagesMsg="true"
        :collageSizeMin="2"
        noImagesMsg="Imagen no disponible"
        height="600px"
        style="position: relative; background-color: rgba(163, 161, 158, 0)"
      >
      </slideshow>
    </div>
  </div>
</template>

<script>
import Slideshow from "vue-collage-slideshow";

export default {
  components: {
    Slideshow,
  },
  data() {
    return {
      images: [
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_1.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_2.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_3.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_4.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_5.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_6.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_7.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_8.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_9.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_10.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_11.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_12.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_13.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_14.png",
        },
        {
          image:
            this.$api.defaults.baseURL +
            "imagenes/" +
            "Categorias/category_15.png",
        },
      ],
    };
  },
  async created() {},
  methods: {},
};
</script>