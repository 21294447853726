<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      class="modal fade"
      :class="[
        { 'show d-block': show },
        { 'd-none': !show },
        { 'modal-mini': type === 'mini' },
      ]"
      v-show="show"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
    >
      <div class="spinner" v-if="show">
        <div class="d-flex align-items-center">
          <b-spinner
            style="width: 15rem; height: 15rem"
            type="grow"
            variant="dark primary"
            class="ml-auto"
          ></b-spinner>
        </div>
        <strong>{{ msg }}</strong>
      </div>
    </div>
  </SlideYUpTransition>
</template>

<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "spinner",
  props: {
    show: Boolean,
    msg: String,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    animationDuration: {
      type: Number,
      default: 500,
    },
  },
  components: {
    SlideYUpTransition,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  watch: {},
};
</script>

<style scoped>
.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 250px;
  left: 0;
  z-index: 5000;
}

.spinner strong {
  display: block;
  margin-right: 8px;
  font-weight: bold !important;
  font-size: 1.5rem !important;
}

.spinner span {
  display: block;
  width: 60px;
  height: 60px;
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>