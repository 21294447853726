<template>
  <div style="padding: 76px 0px 0px 0px">
    <router-view />
  </div>
</template>
<script>
import Navbar from "@/layout/MainNavbar";
import Footer from "@/layout/MainFooter";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>