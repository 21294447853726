<template>
  <div>
    <!------------------------------------------------------>
    <div class="container px-5">
      <div class="row mt-5">
        <div class="col-md-8">
          <card class="mt-2">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="mb-1">Domicilio de entrega</h2>
                  <label class="text-danger"
                    >* VERIFICA QUE TODOS LOS DATOS SEAN CORRECTOS PARA
                    GARANTIZAR TU ENTREGA</label
                  >
                </div>
              </div>

              <!-- Dirección de entrega -->
              <div class="row">
                <div class="col-md-6">
                  <p class="mb-0">
                    <label class="text-primary h6">CP: </label>
                    {{ domicilio.cp }}
                  </p>

                  <p class="mb-0">
                    <label class="text-primary h6">Ciudad: </label>
                    {{ domicilio.ciudad }}
                  </p>
                </div>

                <div class="col-md-6">
                  <p class="mb-0">
                    <label class="text-primary h6">Estado: </label>
                    {{ domicilio.estado }}
                  </p>

                  <p class="mb-0">
                    <label class="text-primary h6">Colonia: </label>
                    {{ domicilio.colonia }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p class="mb-0">
                    <label class="text-primary h6">Calle: </label>
                    {{ domicilio.calle }} # {{ domicilio.ext }}
                    {{ domicilio.int }}
                  </p>
                </div>
              </div>

              <!-- Datos de quien recibe -->
              <div class="row">
                <div class="col-md-12">
                  <p class="mb-0">
                    <label class="text-primary h6">Recibe: </label>
                    {{ client.nombre }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p class="mb-0">
                    <label class="text-primary h6">Teléfono: </label>
                    {{ client.tel }}
                  </p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0">
                    <label class="text-primary h6">Correo: </label>
                    <label
                      v-html="client.email ? splitCorreo(client.email) : ''"
                    ></label>
                  </p>
                </div>
              </div>
              <div class="row mr-1 my-3">
                <div class="col-md-6 text-primary">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="facturar"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Deseo Facturar
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </card>
          <!-- ***********************************************************************************-->
          <card class="mt-2" v-if="facturar">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>Facturación:</h2>
                </div>
              </div>
              <!-- Si  es usuario -->
              <div v-if="profile">
                <div class="row">
                  <div class="col-md-4">
                    <p class="mb-0">
                      <label class="text-primary h6">RFC: </label>
                      {{ facturacion.rfc }}
                    </p>
                  </div>
                  <div class="col-md-8">
                    <p class="mb-0">
                      <label class="text-primary h6">Razón social: </label>
                      {{ facturacion.razon }}
                    </p>
                  </div>
                </div>

                <hr />

                <!-- Dirección de facturación -->
                <div class="row">
                  <div class="col-md-6">
                    <p class="mb-0">
                      <label class="text-primary h6">CP: </label>
                      {{ facturacion.cp }}
                    </p>

                    <p class="mb-0">
                      <label class="text-primary h6">Ciudad: </label>
                      {{ facturacion.ciudad }}
                    </p>
                  </div>

                  <div class="col-md-6">
                    <p class="mb-0">
                      <label class="text-primary h6">Estado: </label>
                      {{ facturacion.estado }}
                    </p>

                    <p class="mb-0">
                      <label class="text-primary h6">Colonia: </label>
                      {{ facturacion.colonia }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Calle: </label>
                      {{ facturacion.calle }} # {{ facturacion.ext }}
                      {{ facturacion.int }}
                    </p>
                  </div>
                </div>
                <!-- ////////////////////////////////////////////////////////// -->
                <hr />
                <div class="row">
                  <div class="col-md-12 mt-2">
                    <fg-select
                      ref="cfdi"
                      v-model="facturacion.cfdi"
                      label="CFDI (obligatorio)"
                      :options="cfdivalues"
                    ></fg-select>
                  </div>
                </div>
                <label class="text-danger"
                  >* SI HAY ALGUN ERROR EN TUS DATOS DE FACTURACIÓN POR FAVOR
                  CONTACTA A TU AGENTE</label
                >
              </div>

              <!-- Si no es usuario -->
              <div v-else>
                <div class="row">
                  <div class="col-md-12">
                    <fg-input
                      label="RFC"
                      ref="rfc"
                      placeholder="RFC"
                      v-model="facturacion.rfc"
                      type="text"
                      onkeyup="javascript:this.value=this.value.toUpperCase();"
                    ></fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <fg-input
                      label="Razon social"
                      ref="razon"
                      placeholder="razón social"
                      v-model="facturacion.razon"
                      type="text"
                      onkeyup="javascript:this.value=this.value.toUpperCase();"
                    ></fg-input>
                  </div>
                </div>
                <!--<div class="row">
                  <div class="col-md-6">
                    <fg-input
                      label="CP"
                      ref="cp"
                      placeholder="cp"
                      v-model="facturacion.cp"
                      type="number"
                      @change="buscarCP(true)"
                    ></fg-input>

                    <fg-select
                      ref="colonia"
                      v-model="facturacion.colonia"
                      label="Colonia"
                      :options="colonias"
                    ></fg-select>
                  </div>

                  <div class="col-md-6">
                    <fg-input
                      label="Estado"
                      ref="estado"
                      placeholder="Estado"
                      v-model="facturacion.estado"
                      readonly
                      type="text"
                    ></fg-input>

                    <fg-input
                      label="Ciudad"
                      ref="ciudad"
                      placeholder="Ciudad"
                      v-model="facturacion.ciudad"
                      type="text"
                      readonly
                    ></fg-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <fg-input
                      label="Calle"
                      ref="calle"
                      placeholder="Calle"
                      v-model="facturacion.calle"
                      type="text"
                    ></fg-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <fg-input
                      label="Exterior"
                      ref="ext"
                      placeholder="Exterior"
                      v-model="facturacion.ext"
                      type="number"
                    ></fg-input>
                  </div>

                  <div class="col-md-6">
                    <fg-input
                      label="Interior"
                      ref="int"
                      placeholder="Interior"
                      v-model="facturacion.int"
                      type="text"
                    ></fg-input>
                  </div>
                </div>-->
                <div class="row">
                  <div class="col-md-12">
                    <fg-select
                      ref="cfdi"
                      v-model="facturacion.cfdi"
                      label="CFDI (obligatorio)"
                      :options="cfdivalues"
                    ></fg-select>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
        <!-- *************************************************************** -->
        <div class="col-md-4">
          <card class="mt-2" style="background: rgb(163, 161, 158)">
            <div class="container">
              <h4 slot="header" class="title title-up mt-1 pt-1 text-white">
                Resumen
              </h4>
              <div class="row">
                <avatar
                  v-for="(item, i) in shoppingCart"
                  :key="i"
                  :src="
                    item.images == 'url' ? noimage : item.images.split(',')[0]
                  "
                ></avatar>
              </div>
            </div>
            <div class="card-text mt-5">
              <div class="row px-1">
                <div class="col-md-6 text-primary"><h5>Subtotal</h5></div>
                <div class="col-md-6 text-right text-primary">
                  <h5>{{ this.$custom.Formatter.format(subtotal) }}</h5>
                </div>
              </div>

              <!--<div class="row px-1">
                <div class="col-md-6 text-white"><h5>Descuentos</h5></div>
                <div class="col-md-6 text-right text-white">
                  <h5>-{{ this.$custom.Formatter.format(descuento) }}</h5>
                </div>
              </div>
              <div class="row px-1">
                <div class="col-md-6 text-white"><h5>Promociones</h5></div>
                <div class="col-md-6 text-right text-white">
                  <h5>-{{ this.$custom.Formatter.format(promocion) }}</h5>
                </div>
              </div>
              <div class="row px-1">
                <div class="col-md-6 text-primary"><h5>IVA</h5></div>
                <div class="col-md-6 text-right text-primary">
                  <h5>{{ this.$custom.Formatter.format(iva) }}</h5>
                </div>
              </div>-->
              <div class="row px-1">
                <div class="col-md-6 text-white"><h5>Envio</h5></div>
                <div class="col-md-6 text-right text-white">
                  <h5>{{ this.$custom.Formatter.format(envio) }}</h5>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6 text-primary"><h4>Total</h4></div>
                <div class="col-md-6 text-primary text-right">
                  <h4>{{ this.$custom.Formatter.format(totalfinal) }}</h4>
                </div>
              </div>
              <div class="row mr-1 my-3">
                <div class="col-md-6 text-white"></div>
                <div class="col-md-6 text-primary text-right">
                  <n-button
                    type="primary"
                    class="mt-0"
                    outline
                    round
                    @click.native="goToResume"
                    :disabled="!canGoResume"
                  >
                    {{ canGoResume ? "Continuar" : "Calculando envio" }}
                  </n-button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <!------------------------------------------------------>
  </div>
</template>

<script>
import {
  Button,
  FormGroupInput,
  FormGroupSelect,
  Card,
  Badge,
  FormGroupInput as FgInput,
  FormGroupSelect as FgSelect,
  NavLink,
  Modal,
} from "@/components";
import { BModal } from "bootstrap-vue";
import round from "vue-round-filter";
import Avatar from "vue-avatar";
import { mask } from "vue-the-mask";
const PUBLIC_PATH = process.env.BASE_URL;

export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [FormGroupSelect.name]: FormGroupSelect,
    Card,
    BModal,
    Badge,
    FgInput,
    FgSelect,
    NavLink,
    [Modal.name]: Modal,
    Avatar,
  },
  filters: {
    round,
  },
  directives: {
    mask,
  },
  data() {
    return {
      rfcMask: [
        "AAAA######AAA",
        "AAAA######AA#",
        "AAAA######A#A",
        "AAAA#######AA",
        "AAAA######A##",
        "AAAA########A",
        "AAAA#########",
        "AAAA#######A#",
        "AAA######AAA",
        "AAA######AA#",
        "AAA######A#A",
        "AAA#######AA",
        "AAA######A##",
        "AAA########A",
        "AAA#########",
        "AAA#######A#",
      ],
      canGoResume: false,
      profile: null,
      timer: null,
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      /////////////////////////////
      subtotal: 0,
      promocion: 0,
      descuento: 0,
      envio: 0,
      totalfinal: 0,
      iva: 0.16,

      promotions: [],
      /////////////////////////////
      errorMsg: null,
      /////////////////////////////
      colonias: [],
      direccion: null,
      domicilio: {
        cp: "",
        estado: "",
        municipio: "",
        ciudad: "",
        colonia: "",
        calle: "",
        int: "",
        ext: "",
      },
      /////////////////////////////
      facturar: false,
      cfdivalues: [
        { value: "G01", text: "G01 - Adquisición de mercancias" },
        {
          value: "G02",
          text: "G02 - Devoluciones, descuentos o bonificaciones",
        },
        { value: "G03", text: "G03 - Gastos en general" },
        { value: "I01", text: "I01 - Construcciones" },
        {
          value: "I02",
          text: "I02 - Mobilario y equipo de oficina por inversiones",
        },
        { value: "I03", text: "I03 - Equipo de transporte" },
        { value: "I04", text: "I04 - Equipo de computo y accesorios" },
        {
          value: "I05",
          text: "I05 - Dados, troqueles, moldes, matrices y herramental",
        },
        { value: "I06", text: "I06 - Comunicaciones telefónicas" },
        { value: "I07", text: "I07 - Comunicaciones satelitales" },
        { value: "I08", text: "I08 - Otra maquinaria y equipo" },
        {
          value: "D01",
          text: "D01 - Honorarios médicos, dentales y gastos hospitalarios",
        },
        {
          value: "D02",
          text: "D02 - Gastos médicos por incapacidad o discapacidad",
        },
        { value: "D03", text: "D03 - Gastos funerales" },
        { value: "D04", text: "D04 - Donativos" },
        {
          value: "D05",
          text: "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
        },
        { value: "D06", text: "D06 - Aportaciones voluntarias al SAR" },
        { value: "D07", text: "D07 - Primas por seguros de gastos médicos" },
        {
          value: "D08",
          text: "D08 - Gastos de transportación escolar obligatoria",
        },
        {
          value: "D09",
          text: "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
        },
        {
          value: "D10",
          text: "D10 - Pagos por servicios educativos (colegiaturas)",
        },
        { value: "P01", text: "P01 - Por definir" },
      ],
      rfcs: [],
      rfc: null,
      facturacion: {
        rfc: null,
        razon: null,
        regimen: null,
        cfdi: null,
      },
      client: {
        id: null,
        email: null,
        nombre: null,
        apellido: null,
        tel: null,
      },
    };
  },
  async created() {
    this.shoppingCart = this.$store.getters.getCarrito();
    if (this.shoppingCart.length > 0) {
      this.profile = this.$decodeToken();

      this.domicilio = this.$store.getters.getDomicilio();
      if (this.domicilio != null) {
        this.envio = this.$store.getters.getEnvio();
        if (this.envio > 0) {
          this.canGoResume = true;

          await this.buscarCP();
          this.domicilio = this.$store.getters.getDomicilio();
          ////////////////////////////////////////////////////
          await this.getRFC();

          if (this.$store.getters.getFacturacion()) {
            this.facturar = true;
            this.facturacion = this.$store.getters.getFacturacion();
          }

          this.getPromotions();

          this.getResume();

          if (this.profile) {
            this.client = {
              email: this.profile.email,
              nombre: this.profile.nombre,
              apellido: this.profile.apellido,
              tel: this.profile.tel,
            };
          } else {
            this.client = this.$store.getters.getInvitado();
            if (this.client == null) {
              this.$router.push({ name: "envio" });
            }
          }

          this.timer = setInterval(() => {
            this.profile = this.$decodeToken();
          }, 500);
        } else {
          this.$router.push({ name: "envio" });
        }
      } else {
        this.$router.push({ name: "envio" });
      }
    } else {
      this.$router.push({ name: "carrito" });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goToResume() {
      let valid = true;
      if (this.facturar) {
        for (var f in this.facturacion) {
          if (f != "regimen") {
            if (this.facturacion[f] == null || this.facturacion[f] == "") {
              this.setValidate(f, false);
              valid = false;
            } else {
              this.setValidate(f, true);
            }
          }
        }
      }

      if (valid) {
        if (this.facturar) {
          this.$store.commit("setFacturacion", this.facturacion);
        } else {
          this.$store.commit("setFacturacion", null);
        }
        this.$router.push({ name: "resume" });
      }
    },
    getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.shoppingCart[n].price * this.shoppingCart[n].cantidad;
      }

      this.totalfinal = this.subtotal + this.envio;
    },
    /*getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.$custom.getHigherPrice(this.shoppingCart[n]) *
          this.shoppingCart[n].cantidad;
        // Si tiene promocion
        if (
          this.promotions.filter((p) => p.codigo == this.shoppingCart[n].codigo)
            .length > 0
        ) {
          //Si es descuento por precio
          if (
            this.promotions.filter(
              (p) => p.codigo == this.shoppingCart[n].codigo
            )[0].precio > 0
          ) {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
          //si es descuento por porcentaje
          else {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
                this.shoppingCart[n].cantidad;

            this.iva +=
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
        //no tiene promocion
        else {
          if (this.profile) {
            this.descuento +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.shoppingCart[n][this.profile.precio] *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.shoppingCart[n][this.profile.precio] *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          } else {
            this.iva +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
      }

      this.totalfinal =
        this.subtotal - this.promocion - this.descuento + this.iva + this.envio;
    },*/
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },

    async buscarCP() {
      this.colonias = [];
      if (this.facturacion.cp) {
        try {
          let response = await this.$cpostal.get(
            this.facturacion.cp + "?token=928fbdb3-1f6d-4c76-93ab-d87a415bcddf"
          );

          let result = response.data;

          result.forEach((element) => {
            this.colonias.push({
              text: element.response.asentamiento,
              value: element.response.asentamiento,
              asentamiento: element.response.asentamiento,
              ciudad:
                element.response.ciudad == ""
                  ? element.response.municipio
                  : element.response.ciudad,
              cp: element.response.cp,
              estado: element.response.estado,
              municipio: element.response.municipio,
              pais: element.response.pais,
              tipo_asentamiento: element.response.tipo_asentamiento,
            });
          });

          if (this.colonias.length > 0) {
            this.facturacion.estado = this.colonias[0].estado;
            this.facturacion.ciudad = this.colonias[0].ciudad;
            this.facturacion.municipio = this.colonias[0].municipio;
          } else {
            this.facturacion.estado = null;
            this.facturacion.ciudad = null;
            this.facturacion.municipio = null;
          }
        } catch (error) {
          this.colonias = [];
          this.facturacion.estado = null;
          this.facturacion.ciudad = null;
          this.facturacion.municipio = null;
        }
      }
    },

    async getRFC() {
      if (this.profile != null) {
        try {
          let response = await this.$api.post(
            "/infoUsuario",
            { id: this.$decodeToken().id },
            { headers: { method: "getRFC" } }
          );

          this.rfcs = response.data.data;
          this.facturacion = this.rfcs[0];
        } catch (error) {
          this.errorMsg = "Error al cargar los datos del cliente";
          setTimeout(this.resolveMsg, 3000);
        }
      }
    },

    /////////////////////////////////////////////////////////////////////
    setValidate(ref, success) {
      if (this.$refs[ref]) {
        if (success) {
          this.$refs[ref].$el.classList.add("has-success");
          this.$refs[ref].$el.classList.remove("has-danger");
        } else {
          this.$refs[ref].$el.classList.remove("has-success");
          this.$refs[ref].$el.classList.add("has-danger");
        }
      }
    },
    splitCorreo(str) {
      return str.replaceAll(";", "<br/>");
    },
  },
  computed: {},
  watch: {
    // whenever question changes, this function will run
    profile: function () {
      if (!this.profile) {
        this.client = this.$store.getters.getInvitado();
        if (this.client == null) {
          this.$router.push({ name: "envio" });
        }
      }
    },
  },
};
</script>
<style scoped>
</style>