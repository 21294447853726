<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <div
        class="page-header-image"
        style="
          background-image: url('background/background_2.png');
          opacity: 0.5;
        "
      ></div>
      <div class="content mt-5">
        <div class="container">
          <div>
            <h1 class="h1-seo mb-0" style="font-weight: 400">¡Contáctanos!</h1>
          </div>

          <div class="row">
            <div class="col-md-6">
              <card type="login" plain>
                <!-- <form data-vv-scope="Contacto"> -->
                <h4>
                  ¿Necesitas ayuda? Envía tus datos y un asesor se comunicará
                  contigo.
                </h4>
                <fg-input
                  ref="name"
                  v-model="contacto.name"
                  class="no-border input-lg"
                  addon-left-icon="now-ui-icons users_circle-08"
                  placeholder="Nombre..."
                ></fg-input>

                <fg-input
                  ref="email"
                  v-model="contacto.email"
                  type="email"
                  class="no-border input-lg"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                  placeholder="Correo electrónico..."
                ></fg-input>

                <fg-input
                  ref="phone"
                  v-model="contacto.phone"
                  v-mask="telefono"
                  class="no-border input-lg"
                  addon-left-icon="now-ui-icons tech_mobile"
                  placeholder="Teléfono..."
                ></fg-input>

                <fg-select
                  ref="asunto"
                  v-model="contacto.asunto"
                  class="no-border input-lg"
                  style="color: red"
                  placeholder="Asunto"
                  :options="[
                    {
                      value: '¿Cómo puedo facturar mi compra?',
                      text: '¿Cómo puedo facturar mi compra?',
                    },
                    {
                      value: '¿Cómo puedo ser distribuidor?',
                      text: '¿Cómo puedo ser distribuidor?',
                    },
                    {
                      value: 'Tengo problemas con mi pedido ¿Cómo lo reviso?',
                      text: 'Tengo problemas con mi pedido ¿Cómo lo reviso?',
                    },
                    {
                      value: '¿Cómo puedo recojer en sucursal?',
                      text: '¿Cómo puedo recojer en sucursal?',
                    },
                    {
                      value: 'Otro',
                      text: 'Otro',
                    },
                  ]"
                ></fg-select>

                <fg-textarea
                  ref="message"
                  v-model="contacto.message"
                  placeholder="Cuentanos como podemos ayudarte, un asesor te contactará"
                  class="no-border input-lg"
                ></fg-textarea>

                <div class="notification mt-5">
                  <badge
                    type="warning"
                    style="font-weight: bold; font-size: 10pt"
                    >{{ errorMsg }}</badge
                  >
                </div>
                <div class="notification mt-5">
                  <badge
                    type="success"
                    style="font-weight: bold; font-size: 12pt"
                    >{{ successMsg }}</badge
                  >
                </div>

                <template slot="raw-content">
                  <div ref="sendEmail" class="card-footer text-center">
                    <button
                      class="btn btn-primary btn-round btn-lg btn-block"
                      @click="sendMail"
                    >
                      Enviar
                    </button>
                  </div>
                </template>

                <!-- </form> -->
              </card>
            </div>

            <div class="col-md-4 offset-md-2 mt-5">
              <card
                type="login"
                plain
                class="contacto"
                style="width: 80%; text-align: right"
              >
                <div class="pull-right contacto" style="margin-top: 30px">
                  <div class="info-contact">
                    <h5>Correo.</h5>
                    <h6>ventas@abvelectricsupply.com</h6>
                  </div>
                  <div class="ico-contact">
                    <i class="now-ui-icons ui-1_send"></i>
                  </div>
                </div>

                <div class="pull-right contacto" style="margin-top: 30px">
                  <div class="info-contact">
                    <h5>WhatsApp.</h5>
                    <h6>4433182650</h6>
                  </div>
                  <div class="ico-contact">
                    <i class="fab fa-whatsapp"></i>
                  </div>
                </div>

                <div class="pull-right contacto" style="margin-top: 30px">
                  
                    <div class="info-contact">
                      <h5>Sucursal.</h5>
                      <h6>
                        Fresno No 210, <br />
                        Col. Jardines del Rincon.<br />Morelia, Mich CP 58270
                      </h6>
                    </div>
                    <div class="ico-contact">
                      <a href="#mapa-google" ><i class="now-ui-icons location_pin"></i></a>
                    </div>
                 
                </div>

                <template slot="raw-content">
                  <div class="card-footer text-center"></div>
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container" id="mapa-google">
        <GoogleMap></GoogleMap>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Badge,
  FormGroupInput,
  FormGroupTextArea,
  FormGroupSelect,
  FormGroupSelect as FgSelect,
} from "@/components";

import GoogleMap from "./GoogleMap";
import { mask } from "vue-the-mask";
export default {
  name: "contact-page",
  bodyClass: "login-page",
  directives: {
    mask,
  },
  components: {
    GoogleMap,
    [Badge.name]: Badge,
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [FormGroupTextArea.name]: FormGroupTextArea,
    [FormGroupSelect.name]: FormGroupSelect,
  },
  data: () => {
    return {
      telefono: "###-###-####",
      //email: {pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, transform: v=> v.toLocaleLowerCase()},
      contacto: {
        name: null,
        email: null,
        phone: null,
        message: null,
        asunto: null,
      },

      reg: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,

      errorMsg: null,
      successMsg: null,

      sucursales: [],
    };
  },
  created() {
    this.getSucursal();
  },
  methods: {
    sendMail: async function () {
      let valido = true;
      for (const key in this.contacto) {
        if (this.contacto[key] == null || this.contacto[key] == "") {
          valido = false;
          this.setValidate(key, false);
        } else {
          this.setValidate(key, true);
        }
      }

      if (valido) {
        try {
          let response = await this.$api.post("/sendMail", this.contacto, {
            headers: { method: "mail-contacto" },
          });

          this.errorMsg = "";
          this.successMsg = "Se envió correctamente el Correo";
        } catch (error) {
          this.errorMsg = "Intenta nuevamente";
          this.successMsg = "";
          this.changeMsg();
        }
      } else {
        this.errorMsg = "Debes completar todos los campos";
        this.successMsg = "";
        this.changeMsg();
      }
    },
    getSucursal: async function () {
      //console.log("listar sucursales");
      try {
        let response = await this.$api.get("/personalization/branchOffices");

        this.sucursales = response.data.data;
      } catch (error) {
        this.sucursales = [];
      }
    },

    changeMsg: function () {
      setTimeout(() => {
        this.errorMsg = "";
        this.successMsg = "";
      }, 4000);
    },
    setValidate(ref, success) {
      if (this.$refs[ref]) {
        if (success) {
          this.$refs[ref].$el.classList.add("has-success");
          this.$refs[ref].$el.classList.remove("has-danger");
        } else {
          this.$refs[ref].$el.classList.remove("has-success");
          this.$refs[ref].$el.classList.add("has-danger");
        }
      }
    },
  },
};
</script>
<style scoped>
.input-lg {
  font-weight: bold;
}
.login-page .card-login {
  max-width: 100%;
}

.input-group,
.form-group {
  margin-bottom: 5px;
}

.login-page .card-login .input-group:last-child {
  margin-bottom: 0px;
}

.ico-contact {
  color: #fce883;
  font-size: 3em;
  display: inline-block;
  margin-left: 30px;
}

.info-contacto {
  display: inline-block;
}

.contacto {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.btn-social_networks {
  background-color: #ffffffa1;
  margin: 10px 25px;
}

.map {
  height: 400px;
}
</style>
