<template>
  <footer
    class="footer"
    style="padding-top: 0px"
    :class="{ [`footer-${type}`]: type }"
  >
    <!-- POLITICAS -->
    <div
      class="copy"
      style="background: white; color: #0085b6; padding: 30px 0"
    >
      <div style="width: 100%; text-align: center">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-12">
              <router-link :to="{ name: 'privacidad' }">
                <div class="row pl-5 my-3 horizontal-center">
                  <i class="fas fa-check fa-3x mr-2"></i>
                </div>

                <div class="row pl-5 my-3 horizontal-center">PRIVACIDAD</div>
              </router-link>
            </div>

            <div class="col-md-4 col-12">
              <router-link :to="{ name: 'envios' }">
                <div class="row pl-5 my-3 horizontal-center">
                  <i class="fas fa-truck fa-3x mr-2"></i>
                </div>
                <div class="row pl-5 my-3 horizontal-center">ENVIOS</div>
              </router-link>
            </div>

            <div class="col-md-4 col-12">
              <router-link :to="{ name: 'tyc' }">
                <div class="row pl-5 my-3 horizontal-center">
                  <i class="fas fa-thumbs-up fa-3x mr-2"></i>
                </div>
                <div class="row pl-5 my-3 horizontal-center">
                  TÉRMINOS Y CONDICIONES
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="container" style="padding: 30px 0">
      <div style="width: 100%; text-align: center">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 col-12">
                <div
                  class="row pl-4 vertical-center text-left "
                  style="font-size: 18pt"
                >
                  Contacto
                </div>
                <div class="row pl-4 vertical-center text-left my-3">
                  <!--<i class="fas fa-map-marker-alt fa-2x mr-2"></i>-->
                  Fresno No. 1013 Col. Jardines del Rincon, Morelia Mich. C.P. 58270
                </div>
                <div class="row pl-4 my-3 text-left vertical-center">
                  <!--<i class="fas fa-at fa-2x mr-2"></i>-->
                  <a
                    href="mailto:ventas@abvelectricsupply.com"
                    style="color: white"
                  >
                    ventas@abvelectricsupply.com</a
                  >
                </div>
                <div class="row pl-4 my-3 text-left vertical-center">
                  <!--<i class="fas fa-phone fa-2x mr-2"></i>-->
                  <a href="tel:4433182650" style="color: white"
                    >(443) 318 2650</a
                  >
                </div>
              </div>
             
              <div class="col-md-4 col-12">
                <div class="row pl-5 " style="font-size: 18pt">
                  Siguenos
                </div>

                <div class="row pl-5 my-3 vertical-center">
                  <i class="fa fa-facebook-official fa-2x mr-2" aria-hidden="true"></i>
                  <a
                    href="https://www.facebook.com/abvelectricoficial"
                    target="_blank"
                    style="color: white"
                  >
                    Facebook</a
                  >
                </div>

                <div class="row pl-5 my-3 vertical-center">
                  <i class="fa fa-instagram fa-2x mr-2"></i>
                  <a
                    href="https://www.instagram.com/abvelectricoficial"
                    target="_blank"
                    style="color: white"
                  >
                    Instagram</a
                  >
                </div>
               
              </div>

              <div class="col-md-4 col-12">
                <div class="row pl-5 " style="font-size: 18pt">
                  Métodos de pago
                </div>
                <div class="row pl-5 my-3 vertical-center">
                  <i class="fa fa-cc-visa fa-2x mr-2" style="color"></i> VISA
                </div>
                <div class="row pl-5 my-3 vertical-center">
                  <i class="fa fa-cc-mastercard fa-2x mr-2" aria-hidden="true"></i> MASTERCARD
                </div>
                <div class="row pl-5 my-3 vertical-center">
                  <i class="fa fa-cc-amex fa-2x mr-2"></i> AMERICAN EXPRESS
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- COPY -->
    <div
      class="copy"
      style="
        background: #000;
        color: white;
        padding: 15px 0;
        font-weight: bold;
        font-size: 10pt;
      "
    >
      Copyright &copy; {{ year }},
      <a href="" target="_blank" rel="noopener" style="color: #FFF"
        >ABV Electric Supply SA de CV</a
      >.
    </div>
  </footer>
</template>
<script>
const PUBLIC_PATH = process.env.BASE_URL;

export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      logo: `${PUBLIC_PATH}brand/logo_conti.png`,
    };
  },
};
</script>
<style scoped>
.vertical-center {
  align-items: center;
  font-size: 12pt;
}
.horizontal-center {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12pt;
  /*color: #001489;*/
}
.right {
  position: absolute;
  right: 0px;
  width: 300px;
  border: 3px solid #73ad21;
  padding: 10px;
}
.left {
  position: absolute;
  left: 0px;
  width: 300px;
  border: 3px solid #73ad21;
  padding: 10px;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
}
</style>