module.exports = {
    Formatter: new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2
    }),
    IsNullOrEmpty: (value) => {
        try {
            return (!value || value == undefined || value == "" || value.length == 0);
        } catch (e) {
            return true;
        }
    },
    getMaxExistencia: (item) => {

        let max = Math.max(
            item.existencianorte,
            item.existenciaallende,
            item.existenciauruapan,
            item.existenciaguadalupe,
            item.existenciacasamata,
            item.existenciagmoprieto
        )

        return max;
    },
    getMinExistencia: (item) => {
        let min = Math.min(
            item.existencianorte,
            item.existenciaallende,
            item.existenciauruapan,
            item.existenciaguadalupe,
            item.existenciacasamata,
            item.existenciagmoprieto
        )

        return min;
    },
        getHigherPrice: (item) => {
            let max = 0;
            for (let i = 1; i < 7; i++) {
                max = Math.max(max, item['precio' + i])
            }
            return max;
        },
    getLowerPrice: (item) => {
        let min = tem['precio1'];
        for (let i = 2; i < 7; i++) {
            min = Math.min(min, item['precio' + i])
        }
        return min;
    },
    comparePrice: (item, precio) => {
        let maxPriceItem = this.getHigherPrice(item);
        return (maxPriceItem > item[precio])
    },
}
