<template>
  <div>
    <Navbar></Navbar>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/layout/MainNavbar";
import Footer from "@/layout/MainFooter";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
