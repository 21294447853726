<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('background/background_6.png');"
    ></div>
    <div class="content" style="height:70vh ">
      <form class="container my-5">
        <div
          id="card-login"
          v-if="!forgotpass"
          class="col-md-5 ml-auto mr-auto login-class"
        >
          <card type="login" plain>
            <!--<div slot="header" class="logo-container">
              <img v-lazy="'brand/Layer 0.png'" alt="" />
            </div>-->

            <fg-input
              v-model="usuario.usuario"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons users_circle-08"
              placeholder="Email"
            ></fg-input>

            <fg-input
              v-model="usuario.password"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons objects_key-25"
              placeholder="Contraseña"
              type="password"
              autocomplete="on"
            ></fg-input>

            <template slot="raw-content">
              <div class="card-footer text-center">
                <a
                  @click="Login"
                  class="btn btn-primary btn-round btn-lg btn-block"
                  >Iniciar sesión</a
                >
              </div>

              <div class="pull-right">
                <h6>
                  <a @click="forgotpass = true" class="link footer-link"
                    >Olvidé mi contraseña</a
                  >
                </h6>
              </div>
            </template>
          </card>
        </div>
      </form>

      <!-- Recuperar contraseña-->
      <div v-if="forgotpass" class="my-5">
        <div class="container">
          <div class="row">
            <div class="col-md-10 ml-auto mr-auto">
              <div class="pull-left">
                <h6>
                  <i class="now-ui-icons arrows-1_minimal-left"></i>
                  <a
                    href="#"
                    @click="forgotpass = false"
                    class="link footer-link"
                    >Volver</a
                  >
                </h6>
              </div>
            </div>
          </div>
          <br />
          <div class="col-md-5 ml-auto mr-auto login-class">
            <card type="login" plain>
              <fg-input
                v-model="recuperacion.email"
                class="no-border input-lg"
                addon-left-icon="now-ui-icons users_circle-08"
                placeholder="Usuario"
              ></fg-input>

              <template slot="raw-content">
                <div class="card-footer text-center">
                  <a
                    href="#"
                    @click="Forget"
                    class="btn btn-primary btn-round btn-lg btn-block"
                    >Enviar correo de recuperación</a
                  >
                </div>
              </template>
            </card>
            <br />
          </div>
        </div>
      </div>
      <!-- Mensajes de error -->
      <div class="notification">
        <badge type="danger">{{ errorMsg }}</badge>
      </div>
      <div class="notification">
        <badge type="info">{{ successMsg }}</badge>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Badge, FormGroupInput } from "@/components";
import { mask } from "vue-the-mask";
export default {
  name: "login-page",
  bodyClass: "login-page",
  directives: {
    mask,
  },
  components: {
    Card,
    //MainFooter,
    [Badge.name]: Badge,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data: () => {
    return {
      usuario: {
        usuario: null,
        password: null,
      },
      recuperacion: {
        email: null,
      },
      signin: false,
      forgotpass: false,
      errorMsg: null,
      successMsg: null,
      regMail: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
      regRFC: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
    };
  },
  created() {
    if (this.$decodeToken() != null) {
      this.$router.push({ name: "cliente" });
    }
  },
  methods: {
    Login: function () {
      this.errorMsg = "";
      if (
        this.regMail.test(this.usuario.usuario) ||
        this.usuario.usuario.length >= 6
      ) {
        this.$api
          .post("/login", this.usuario, { headers: { method: "enviarMail" } })
          .then((response) => {
            this.$store.commit("setToken", response.data.token);
            if (this.$decodeToken().rol == "admin_ecommerce") {
              this.$router.push({ name: "dashboard" });
            } else if (this.$decodeToken().rol == "cliente_ecommerce") {
              this.$router.push({ name: "cliente" });
            }
            this.clearMsg();
          })
          .catch((error) => {
            this.errorMsg = "Usuario desconocido";
            this.clearMsg();
          });
      } else {
        if (this.usuario.usuario == "" || this.usuario.usuario == null) {
          this.errorMsg = "Debes ingresar tu numero de cliente";
        } else if (
          this.usuario.password == "" ||
          this.usuario.password == null
        ) {
          this.errorMsg = "Debes ingresar una contraseña";
        } else {
          this.errorMsg = "Los datos ingresados no son validos";
        }
      }
    },
    Forget: function () {
      if (
        this.regMail.test(this.usuario.usuario) ||
        this.usuario.usuario.length >= 6
      ) {
        this.$api
          .post(
            "/forget",
            { id: this.recuperacion.email },
            { headers: { method: "enviarMail" } }
          )
          .then((response) => {
            this.errorMsg = "";
            this.successMsg =
              "Se envió un correo a su cuenta para recuperar su contraseña";
            this.clearMsg();
            this.forgotpass = false;
          });
      } else {
        this.errorMsg = "Ingresa un correo válido";
        this.clearMsg();
      }
    },
    clearMsg: function () {
      setTimeout(() => {
        this.errorMsg = "";
        this.successMsg = "";
      }, 5000);
    },
  },
};
</script>
<style scoped>
#card-login {
  padding-top: 50px;
}
</style>
