<template>
  <div class="container px-5">
    <div class="row mt-5">
      <div class="col-md-8">
        <div class="row">
          <h4 slot="header" class="title title-up">¿Cómo quieres pagar?</h4>
        </div>
        <div class="row my-3">
          <p class="mb-0">Pago con tarjeta</p>
          <i class="fab fa-cc-mastercard fa-2x mx-2"></i>
          <i class="fab fa-cc-visa fa-2x mx-2"></i>
          <i class="fab fa-cc-amex fa-2x mx-2"></i>
        </div>
        <div class="row mr-5 mb-1">
          <card :style="options == 1 ? 'border-left: 3px solid #001489' : ''">
            <h4 class="card-title my-0">
              <n-radio v-model="options" label="1"
                >1 x {{ this.$custom.Formatter.format(totalfinal) }}</n-radio
              >
            </h4>
          </card>
        </div>
        <div class="row mr-5 mb-1" v-if="totalfinal >= 300">
          <card :style="options == 3 ? 'border-left: 3px solid #001489' : ''">
            <h4 class="card-title my-0">
              <n-radio v-model="options" label="3"
                >3 x {{ sobretasa(3) }}</n-radio
              >
            </h4>
          </card>
        </div>
        <!--<div class="row mr-5 mb-1" v-if="totalfinal >= 600">
          <card :style="options == 6 ? 'border-left: 3px solid #001489' : ''">
            <h4 class="card-title my-0">
              <n-radio v-model="options" label="6"
                >6 x {{ sobretasa(6) }}</n-radio
              >
            </h4>
          </card>
        </div>-->
        <!--<div class="row mr-5 mb-1" v-if="totalfinal >= 900">
          <card :style="options == 9 ? 'border-left: 3px solid #001489' : ''">
            <h4 class="card-title my-0">
              <n-radio v-model="options" label="9"
                >9 x {{ sobretasa(9) }}</n-radio
              >
            </h4>
          </card>
        </div>-->
        <!--<div class="row mr-5 mb-1">
          <card :style="options == 12 ? 'border-left: 3px solid #001489' : ''">
            <h4 class="card-title my-0">
              <n-radio v-model="options" label="12"
                >12 x {{ sobretasa(12) }}</n-radio
              >
            </h4>
          </card>
        </div>-->
        <!--<div class="row mr-5" v-if="totalfinal >= 1800">
          <card :style="options == 18 ? 'border-left: 3px solid #001489' : ''">
            <h4 class="card-title my-0">
              <n-radio v-model="options" label="18"
                >18 x {{ sobretasa(18) }}</n-radio
              >
            </h4>
          </card>
        </div>-->
        <!-- *************************************************************** - ->
        <div class="row my-3">
          <p class="mb-0">Otras formas de pago</p>
        </div>
        <div class="row mr-5">
          <card
            :style="options == 'coppel' ? 'border-left: 3px solid #001489' : ''"
          >
            <h4 class="card-title my-0">
              <n-radio v-model="options" label="coppel">Coppel Pay</n-radio>
            </h4>
          </card>
        </div>-->
      </div>
      <!-- *************************************************************** -->
      <div class="col-md-4 mt-5 pt-5">
        <card class="mt-5" style="background-color: rgb(163, 161, 158)">
          <div class="container">
            <h4 slot="header" class="title title-up mt-1 pt-1">Resumen</h4>
            <div class="row">
              <avatar
                v-for="(item, i) in shoppingCart"
                :key="i"
                :src="item.images == 'url' ? noimage : item.images.split(',')[0]"
              ></avatar>
            </div>
          </div>
          <div class="card-text mt-5">
            <div class="row px-1">
              <div class="col-md-6 text-primary"><h5>subtotal</h5></div>
              <div class="col-md-6 text-right text-primary">
                <h5>{{ this.$custom.Formatter.format(subtotal) }}</h5>
              </div>
            </div>

            <!--<div class="row px-1">
              <div class="col-md-6 text-white"><h5>Promociones</h5></div>
              <div class="col-md-6 text-right text-white">
                <h5>-{{ this.$custom.Formatter.format(promocion) }}</h5>
              </div>
            </div>
            <div class="row px-1">
              <div class="col-md-6 text-white"><h5>Descuentos</h5></div>
              <div class="col-md-6 text-right text-white">
                <h5>-{{ this.$custom.Formatter.format(descuento) }}</h5>
              </div>
            </div>
            <div class="row px-1">
              <div class="col-md-6 text-primary"><h5>IVA</h5></div>
              <div class="col-md-6 text-right text-primary">
                <h5>{{ this.$custom.Formatter.format(iva) }}</h5>
              </div>
            </div>-->
            <div class="row px-1">
              <div class="col-md-6 text-primary"><h5>Envio</h5></div>
              <div class="col-md-6 text-right text-primary">
                <h5>{{ this.$custom.Formatter.format(envio) }}</h5>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-6 text-primary"><h4>Total</h4></div>
              <div class="col-md-6 text-primary text-right">
                <h4>{{ this.$custom.Formatter.format(totalfinal) }}</h4>
              </div>
            </div>
            <div class="row mr-1 my-3">
              <div class="col-md-6 text-white">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="facturar"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                  disabled
                >
                  Facturar
                </b-form-checkbox>
              </div>
              <div class="col-md-6 text-primary text-right">
                <n-button
                  type="primary"
                  class="mt-0"
                  outline
                  round
                  @click.native="goToPay"
                  :disabled="!canGoResume"
                >
                  {{ canGoResume ? "Continuar" : "Calculando envio" }}
                </n-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
const PUBLIC_PATH = process.env.BASE_URL;

import {
  Button,
  FormGroupInput,
  Card,
  Modal,
  Pagination,
  Badge,
  Radio,
} from "@/components";

import Avatar from "vue-avatar";

export default {
  name: "app",
  components: {
    Avatar,
    Card,
    [Button.name]: Button,
    [Radio.name]: Radio,
  },
  data() {
    return {
      profile: null,
      canGoResume: false,
      timer: null,

      facturar: false,
      subtotal: 0,
      iva: 0,
      promocion: 0,
      descuento: 0,
      envio: 0,
      totalfinal: 0,

      options: "1",
      ivasobretasa: 0.16,
      sobretasas: {
        1: 0,
        3: 0.0466,
        6: 0.0751,
        9: 0.1086,
        12: 0.1363,
        18: 0.1999,
      },
      promotions: [],
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      /////////////////////////////
      colonias: [],
      domicilio: {
        cp: "",
        estado: "",
        municipio: "",
        ciudad: "",
        colonia: "",
        calle: "",
        int: "",
        ext: "",
      },
      /////////////////////////////

      rfcs: [],
      rfc: null,
      facturacion: {
        rfc: null,
        razon: null,
        regimen: null,
        cfdi: null,
      },
      client: {
        id: null,
        email: null,
        nombre: null,
        apellido: null,
        tel: null,
      },

      shopping: [],
    };
  },
  async created() {
    this.shoppingCart = this.$store.getters.getCarrito();
    if (this.shoppingCart.length > 0) {
      this.profile = this.$decodeToken();

      this.domicilio = this.$store.getters.getDomicilio();
      if (this.domicilio != null) {
        this.envio = this.$store.getters.getEnvio();
        if (this.envio > 0) {
          this.canGoResume = true;

          this.facturacion = this.$store.getters.getFacturacion();
          if (this.facturacion != null) {
            this.facturar = true;
          }
          this.getPromotions();

          this.getResume();

          this.timer = setInterval(() => {
            this.profile = this.$decodeToken();
          }, 500);
        } else {
          this.$router.push({ name: "envio" });
        }
      } else {
        this.$router.push({ name: "envio" });
      }
    } else {
      this.$$router.push({ name: "carrito" });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    sobretasa(msi) {
      let cobrar =
        this.totalfinal /
        (1 - (this.sobretasas[msi] * this.ivasobretasa + this.sobretasas[msi]));
      return this.$custom.Formatter.format((cobrar / msi).toFixed(2));
    },
    getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.shoppingCart[n].price * this.shoppingCart[n].cantidad;
      }

      this.totalfinal = this.subtotal + this.envio;
    },
    /*getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.$custom.getHigherPrice(this.shoppingCart[n]) *
          this.shoppingCart[n].cantidad;
        // Si tiene promocion
        if (
          this.promotions.filter((p) => p.codigo == this.shoppingCart[n].codigo)
            .length > 0
        ) {
          //Si es descuento por precio
          if (
            this.promotions.filter(
              (p) => p.codigo == this.shoppingCart[n].codigo
            )[0].precio > 0
          ) {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
          //si es descuento por porcentaje
          else {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
                this.shoppingCart[n].cantidad;

            this.iva +=
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
        //no tiene promocion
        else {
          if (this.profile) {
            this.descuento +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.shoppingCart[n][this.profile.precio] *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.shoppingCart[n][this.profile.precio] *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          } else {
            this.iva +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
      }

      this.totalfinal =
        this.subtotal - this.promocion - this.descuento + this.iva + this.envio;
    },*/
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },

    goToPay() {
      this.$store.commit("setMSI", this.options);
      if (this.options == "coppel") {
        this.$router.push({ name: "coppel" });
      } else {
        this.$router.push({ name: "netpay" });
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    profile: function () {
      if (!this.profile) {
        this.client = this.$store.getters.getInvitado();
        if (this.client == null) {
          this.$router.push({ name: "envio" });
        }
      }
    },
  },
};
</script>
<style scoped>
.derecha {
  float: right;
}
</style>>

