<template>
  <div>
    <!--  Paralax de portada-- >
    <div
      v-if="promotions.length > 0 && products.length>0"
      class="page-header clear-filter"
      filter-color="orange"
    >
      <carousel
        :perPage="1"
        :autoplay="true"
        paginationActiveColor="#001489"
        paginationColor="#A3A19E"
        easing="linear"
        :autoplayHoverPause="true"
        paginationPosition="bottom-overlay"
        :loop="true"
        :centerMode="true"
        :adjustableHeight="true"
        style="max-height: 92vh; min-height: 92vh"
      >
        <slide v-for="(ms, $index) in promotions" :key="$index">
          <img
            v-if="ms.url == 'url'"
            class=""
            :src="background"
            style="max-height: 92vh; min-height: 92vh; width: 100%"
          />
          <img
            v-else
            class=""
            :src="ms.url"
            style="max-height: 92vh; min-height: 92vh; width: 100%"
          />
        </slide>
      </carousel>
    </div>
    <br />-->
    <!-- Catalogo de productos -->
    <div class="row mt-5">
      <div class="col-md-2">
        <div class="container pl-5">
          <!-- Barra de busqueda -->
          {{ queryFilter }}
          <div class="row">
            <fg-input
              v-model="pista"
              placeholder="Buscar producto"
              addon-left-icon="now-ui-icons ui-1_zoom-bold"
              @keyup="filterList"
              class="mx-0 px-0"
            ></fg-input>
          </div>

          <!-- Filtro departamentos -->
          <div class="row" v-for="line in lines" :key="line.key">
            <b-form-checkbox
              :id="'checkbox-' + line.key"
              v-model="line.value"
              :name="'checkbox-' + line.key"
              @change="filterList"
            >
              {{ line.text }}
            </b-form-checkbox>
          </div>
        </div>
      </div>

      <div class="col-md-10 px-5">
        <!-- Layout de productos -->
        <div class="row">
          <!-- PAGINAGDOR -->
          <div class="col col-md-12">
            <n-pagination
              class="justify-content-center"
              :page-count="Math.ceil(filter.length / 12)"
              v-model="selectedPage"
            >
              <span slot="prev">Anterior</span>
              <span slot="next">Siguiente</span>
            </n-pagination>
          </div>
        </div>
        <div class="row" v-if="getPage.length > 0">
          <div
            class="col-12 col-md-2"
            v-for="(n, $index) in getPage"
            :key="$index"
          >
            <card class="col-12" plain cardBodyClasses="px-0 py-0">
              <!-- Carousel de imagenes -->
              <center>
                <carousel
                  :perPageCustom="[
                    [380, 1],
                    [668, 1],
                  ]"
                  :autoplayHoverPause="true"
                  :autoplay="true"
                  :loop="true"
                  paginationActiveColor="#001489"
                  paginationColor="#A3A19E"
                  easing="ease-in-out"
                  :paginationSize="Number(5)"
                  :autoplayTimeout="8000"
                >
                  <slide
                    v-for="(nn, $index) in n.images.split(',').length > 5
                      ? 5
                      : n.images.split(',').length"
                    :key="$index"
                  >
                    <img
                      v-if="n.images == 'url'"
                      class="producto"
                      :src="noimage"
                    />
                    <img
                      v-else
                      class="producto"
                      :src="n.images.split(',')[nn - 1]"
                    />
                  </slide>
                  <slide v-if="n.images.split(',').length == 1" :key="$index">
                    <img
                      v-if="n.images.split(',')[0] == 'url'"
                      class="producto"
                      :src="noimage"
                    />
                    <img
                      v-else
                      class="producto"
                      :src="n.images.split(',')[0]"
                    />
                  </slide>
                </carousel>
              </center>
              <!-- Precio del producto -->
              <center>
                <div class="card-title my-0" style="height: 38px">
                  {{ n.model }}
                </div>

                <!-- Precio mayor si no hay sesion-->
                <h5 class="mb-0" style="font-size: 1.1em">
                  {{ $custom.Formatter.format(n.price) }}
                </h5>
              </center>
              <!-- Buton modal de detalles -->
              <center>
                <n-button
                  type="neutral"
                  class="my-0"
                  text
                  round
                  @click.native="modaldetails(n)"
                  >Ver</n-button
                >
              </center>
            </card>
            <hr />
          </div>
        </div>
        <div class="row" v-else>
          <div
            class="col-12 col-md-12 text-center pt-5 mt-5"
            style="min-height: 60vh"
          >
            <h1 class="subtitleABV text-center">No hay datos para mostrar</h1>
          </div>
        </div>
        <div class="row">
          <!-- PAGINAGDOR -->
          <div class="col col-md-12">
            <n-pagination
              class="justify-content-center"
              :page-count="Math.ceil(filter.length / 12)"
              v-model="selectedPage"
            >
              <span slot="prev">Anterior</span>
              <span slot="next">Siguiente</span>
            </n-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Detalles-->
    <modal
      :show.sync="modals.detalles"
      :show-close="true"
      modal-classes="modal-lg"
      header-classes="justify-content-center"
    >
      <h4 slot="header" class="title title-up">{{ selectedItem.title }}</h4>
      <div>
        <carousel
          :perPage="3"
          :autoplay="true"
          paginationActiveColor="#001489"
          paginationColor="#A3A19E"
          easing="linear"
          :autoplayHoverPause="true"
          paginationPosition="bottom-overlay"
          :loop="true"
          :centerMode="true"
          :paginationSize="Number(3)"
          v-if="selectedItem.images"
        >
          <slide
            v-for="(nn, $index) in selectedItem.images.split(',').length > 5
              ? 5
              : selectedItem.images.split(',').length"
            :key="$index"
          >
            <img
              v-if="selectedItem.images == 'url'"
              class="producto"
              :src="noimage"
            />
            <img
              v-else
              class="producto"
              :src="selectedItem.images.split(',')[nn - 1]"
            />
          </slide>
          <slide
            v-if="selectedItem.images.split(',').length == 1"
            :key="$index"
          >
            <img
              v-if="selectedItem.images.split(',')[0] == 'url'"
              class="producto"
              :src="noimage"
            />
            <img
              v-else
              class="producto"
              :src="selectedItem.images.split(',')[0]"
            />
          </slide>
        </carousel>
      </div>
      <template class="justify-content-center titulo">
        <center>
          <!-- Precio mayor si no hay sesion-->
          <h4>
            {{ $custom.Formatter.format(selectedItem.price) }}
          </h4>
        </center>

        <div class="row">
          <div class="col-md-12">
            <h4>DESCRIPCIÓN :</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="selectedItem.description != undefined">
            <h5>{{ selectedItem.description }}</h5>
          </div>
        </div>

        <hr />

        <div class="row justify-content-center"></div>
        <template slot="footer">
          <n-button
            type="default"
            class="mt-3"
            outline
            round
            @click.native="showDetails()"
          >
            Ver detalles
          </n-button>

          <fg-input
            ref="selectedCount"
            label="Cantidad"
            v-on:keyup.esc="cerrarModales()"
            v-model="cantidad"
            placeholder="cantidad"
            value="1"
            type="number"
            min="1"
            class="mt-0"
            :max="$custom.getMaxExistencia(selectedItem)"
          ></fg-input>

          <n-button
            type="primary"
            class="mt-3"
            outline
            round
            @click.native="agregar(selectedItem, cantidad)"
          >
            Al carrito
            <i class="fas fa-shopping-cart"></i>
          </n-button>
        </template>
        <div class="notification">
          <center>
            <badge type="danger">{{ messageError }}</badge>
          </center>
        </div>
      </template>
    </modal>
    <!-- Modal Carrito-->
    <modal
      :show.sync="modals.carrito"
      v-on:keyup.esc="cerrarModales()"
      :show-close="false"
      class="modal-default"
      header-classes="justify-content-center"
      type="notice"
    >
      <div
        slot="header"
        class="modal-profile d-flex justify-content-center align-items-center"
      >
        <b-icon
          icon="check2-all"
          style="width: 100px; height: 100px"
          variant="success"
          aria-hidden="true"
        ></b-icon>
      </div>

      <h4>Se ha agregado a tu carrito correctamente</h4>

      <template slot="footer">
        <n-button type="info" link @click.native="cerrarModales()"
          ><b>Continuar comprando</b></n-button
        >
        <n-button type="primary" link @click.native="irACarrito()"
          ><b>Ver carrito</b></n-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import {
  Button,
  FormGroupInput,
  Card,
  Modal,
  Pagination,
  Badge,
  Checkbox,
} from "@/components";

import { Carousel, Slide } from "vue-carousel";
import round from "vue-round-filter";

const PUBLIC_PATH = process.env.BASE_URL;
import CryptoJS from "crypto-js";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    Badge,
    Card,
    Carousel,
    Checkbox,
    Slide,

    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Modal.name]: Modal,
    [Pagination.name]: Pagination,
  },
  filters: {
    round,
  },
  data() {
    return {
      queryFilter: null,
      loading: true,
      timer: null,
      profile: null,
      banners: [],
      background: `${PUBLIC_PATH}background/background_3.png`,
      imagen: `${PUBLIC_PATH}brand/prod.png`,
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      modals: {
        detalles: false,
        carrito: false,
      },
      promotions: [],
      products: [],
      filter: [],
      pageCount: 0,
      linesFilter: [],
      lines: [],
      sublinesFilter: [],
      sublines: [],
      selectedPage: 1,

      selectedItem: {},
      cantidad: 0,
      pista: "",

      messageError: "",
    };
  },
  async created() {
    this.profile = this.$decodeToken();
    this.timer = setInterval(() => {
      this.profile = this.$decodeToken();
    }, 500);
    //this.loading = true;
    await this.getPromotions();
    await this.getproducts();

    if (this.$route.query.linea) {
      this.queryFilter = this.$route.query.linea.replace(/%20/g, " ");
      this.filterList();
    }
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  methods: {
    getproducts() {
      // if (this.$store.getters.getProductos() == null) {
      this.$api
        .get("/productos", { headers: { method: "listar" } })
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
            this.products = response.data.data;
            this.filter = response.data.data;
            this.lines = response.data.lines;

            // let storeProducts = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), 'secret key 123').toString();

            //localStorage.setItem("setProductos", storeProducts);
            //this.loading = false;
          }
        })
        .catch((error) => {
          this.products = [];
          this.filter = [];
          this.lines = [];
          this.loading = false;
        });
      /*} else {
        let storeProducts = CryptoJS.AES.decrypt(localStorage.getItem("setProductos"), 'secret key 123');
     
        this.products = storeProducts;
        this.filter = storeProducts;

        //this.lines = storeProducts.lines;
         this.loading = false;
      }*/
    },
    modaldetails(item) {
      this.selectedItem = item;
      this.cantidad = 1;
      this.messageError = "";
      this.$refs.selectedCount.$el.getElementsByTagName("input")[0].focus();
      this.modals.detalles = true;
    },
    showDetails() {
      /* let routeData = this.$router.resolve({
        name: "details",
        params: {
          id: this.selectedItem.clave,
        },
      });
      window.open(routeData.href, "_blank");*/
      this.$router.push({
        name: "details",
        params: {
          id: this.selectedItem.model,
        },
      });
    },
    irACarrito() {
      this.$router.push({ name: "carrito" });
    },
    cerrarModales() {
      this.selectedItem = {};
      this.modals.carrito = false;
      this.modals.detalles = false;
    },
    agregar(item, cantidad) {
      new Promise(async (reject, resolve) => {
        if (Number(cantidad) > 0) {
          //1. Leer storage
          this.messageError = "";
          let carritoItems = this.$store.getters.getCarrito();

          //2.- Buscar producto en carrito
          let enCarrito = await carritoItems.filter(
            (e) => e.model == item.model
          );

          //3.- Es nuevo el producto?
          if (enCarrito.length > 0) {
            const index = carritoItems.indexOf(enCarrito[0]);
            if (index > -1) {
              carritoItems.splice(index, 1);
            }
            item.cantidad = Number(cantidad) + Number(enCarrito[0].cantidad);
            carritoItems.push(item);
          } else {
            item.cantidad = Number(cantidad);
            carritoItems.push(item);
          }

          //3. Actualizar carrito
          this.$store.commit("setCarrito", carritoItems);
          this.modals.detalles = false;
          this.modals.carrito = true;
        } else {
          this.messageError = "Selecciona la cantidad de árticulos";
        }
      });
    },
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },
    isPromotion: async function (codigo) {
      let list = [];
      list = await this.promotions.filter(async (e) => {
        return e.model == codigo;
      });

      return list.length > 0;
    },
    filterList: async function () {
      let list = [];

      let lines = [];

      if (this.queryFilter != null) {
        let category = this.lines.filter()
        
        await this.asyncForEach(this.lines, async (l) => {
          if (l.text == this.queryFilter) {
            l.value = true;
          }
        });
      }

      /**--------------------------------------------------------------------- */
      await this.asyncForEach(this.lines, async (l) => {
        if (l.value == true) {
          lines.push(l.text);
        }
      });

      if (this.pista.length == 0) {
        list = this.products;
      } else {
        list = await this.products.filter((e) => {
          return (
            e.model.includes(this.pista.toUpperCase()) ||
            e.title.includes(this.pista.toUpperCase())
          );
        });
      }

      if (lines.length > 0) {
        list = await list.filter((e) => {
          return lines.includes(e.line.toUpperCase());
        });
        this.filter = list;
      } else {
        this.filter = list;
      }
    },
    //////////////////////////////////////////////////////////////////////
    asyncForEach: async function (array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
  },
  computed: {
    getPage: function () {
      return this.filter.slice(
        (this.selectedPage - 1) * 13,
        this.selectedPage * 13 - 1
      );
    },
  },
};
</script>
<style scoped>
h6 {
  margin-bottom: 0;
  margin-top: 0.5rem;
}
img {
  display: center;
}

.titulo {
  background-color: #a3a19e;
  color: #001489;
}
.producto {
  width: 100%;
  height: auto;
  max-height: 200px;
}
</style>