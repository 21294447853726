<template>
  <div>
    <!------------------------------------------------------>
    <div class="container px-5">
      <div class="row mt-5">
        <div class="col-md-8">
          <card class="mt-2">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>Domicilio de entrega:</h2>
                  <label class="text-danger"
                    >* VERIFICA QUE TODOS LOS DATOS SEAN CORRECTOS PARA
                    GARANTIZAR TU ENTREGA</label
                  >
                </div>
              </div>
              <div class="row" v-if="profile != null">
                <div class="col-md-12">
                  <fg-select
                    v-model="direccion"
                    label="Domicilios guardados"
                    :options="direcciones"
                    @change="seleccionarDireccion()"
                  ></fg-select>
                </div>
              </div>
              <hr />
              <!-- Dirección de entrega -->
              <div class="row">
                <div class="col-md-4">
                  <fg-input
                    ref="cp"
                    v-model="domicilio.cp"
                    type="text"
                    v-mask="cp"
                    label="Código Postal"
                    @change="buscarCP(true)"
                    required
                  ></fg-input>

                  <fg-input
                    ref="ciudad"
                    v-model="domicilio.ciudad"
                    label="Ciudad"
                    disabled
                    required
                  ></fg-input>
                </div>
                <div class="col-md-8">
                  <fg-input
                    ref="estado"
                    v-model="domicilio.estado"
                    label="Estado"
                    disabled
                    required
                  ></fg-input>

                  <fg-input
                    ref="municipio"
                    v-model="domicilio.municipio"
                    label="Municipio"
                    disabled
                    required
                  ></fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-select
                    ref="colonia"
                    v-model="domicilio.colonia"
                    label="Colonia"
                    :options="colonias"
                    required
                  ></fg-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input
                    ref="calle"
                    v-model="domicilio.calle"
                    label="Calle"
                    required
                  ></fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <fg-input
                    ref="ext"
                    v-model="domicilio.ext"
                    type="number"
                    label="Número exterior"
                    required
                  ></fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input
                    ref="int"
                    v-model="domicilio.int"
                    type="text"
                    label="Número interior"
                  ></fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input
                    ref="tel"
                    v-model="client.tel"
                    v-mask="telefono"
                    label="Teléfono de contacto"
                    required
                  ></fg-input>
                </div>
              </div>
              <br />

              <!-- Datos de quien recibe -->
              <div class="row">
                <div class="col-md-12">
                  <fg-input
                    ref="nombre"
                    v-model="client.nombre"
                    label="Nombre de quien recibe"
                    required
                  ></fg-input>
                </div>
              </div>
              <div class="row" v-if="profile == null">
                <div class="col-md-12">
                  <fg-input
                    ref="email"
                    v-model="client.email"
                    label="Correo de contacto"
                    required
                  ></fg-input>
                  <label class="text-danger"
                    >* Este correo será usado para el seguimiento de pedido y el envio de comprobantes</label
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
        <!-- *************************************************************** -->
        <div class="col-md-4">
          <card class="mt-2" style="background: rgb(163, 161, 158)">
            <div class="container">
              <h4 slot="header" class="title title-up mt-1 pt-1 text-white">
                Resumen
              </h4>
              <div class="row">
                <avatar
                  v-for="(item, i) in shoppingCart"
                  :key="i"
                  :src="item.images == 'url' ? noimage : item.images.split(',')[0]"
                ></avatar>
              </div>
            </div>
            <div class="card-text mt-5">
              <div class="row px-1">
                <div class="col-md-6 text-primary"><h5>Subtotal</h5></div>
                <div class="col-md-6 text-right text-primary">
                  <h5>{{ this.$custom.Formatter.format(subtotal) }}</h5>
                </div>
              </div>

              <!--<div class="row px-1">
                <div class="col-md-6 text-white"><h5>Descuentos</h5></div>
                <div class="col-md-6 text-right text-white">
                  <h5>-{{ this.$custom.Formatter.format(descuento) }}</h5>
                </div>
              </div>
              <div class="row px-1">
                <div class="col-md-6 text-white"><h5>Promociones</h5></div>
                <div class="col-md-6 text-right text-white">
                  <h5>-{{ this.$custom.Formatter.format(promocion) }}</h5>
                </div>
              </div>
              <div class="row px-1">
                <div class="col-md-6 text-primary"><h5>IVA</h5></div>
                <div class="col-md-6 text-right text-primary">
                  <h5>{{ this.$custom.Formatter.format(iva) }}</h5>
                </div>
              </div>-->
              <div class="row px-1">
                <div class="col-md-6 text-white"><h5>Envio</h5></div>
                <div class="col-md-6 text-right text-white">
                  <h5>{{ this.$custom.Formatter.format(envio) }}</h5>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6 text-primary"><h4>Total</h4></div>
                <div class="col-md-6 text-primary text-right">
                  <h4>{{ this.$custom.Formatter.format(totalfinal) }}</h4>
                </div>
              </div>
              <div class="row mr-1 my-3">
                <div class="col-md-6 offset-md-6 text-primary text-right">
                  <n-button
                    type="primary"
                    class="mt-0"
                    outline
                    round
                    @click.native="goToResume"
                    :disabled="!canContinue"
                  >
                    {{ canContinue ? "Continuar" : (domicilio.cp?"Calculando envio":"Dirección in accesible") }}
                  </n-button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <!------------------------------------------------------>
  </div>
</template>

<script>
import {
  Button,
  FormGroupInput,
  FormGroupSelect,
  Card,
  Badge,
  FormGroupInput as FgInput,
  FormGroupSelect as FgSelect,
  NavLink,
  Modal,
} from "@/components";
import { BModal } from "bootstrap-vue";
import round from "vue-round-filter";
import Avatar from "vue-avatar";
import { mask } from "vue-the-mask";
const PUBLIC_PATH = process.env.BASE_URL;

export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [FormGroupSelect.name]: FormGroupSelect,
    Card,
    BModal,
    Badge,
    FgInput,
    FgSelect,
    NavLink,
    [Modal.name]: Modal,
    Avatar,
  },
  filters: {
    round,
  },
  directives: {
    mask,
  },
  data() {
    return {
      telefono: "###-###-####",
      cp: "#####",
      profile: null,
      timer: null,
      canContinue: false,
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      /////////////////////////////
      subtotal: 0,
      descuento: 0,
      promocion: 0,
      envio: 0,
      iva: 0,
      totalfinal: 0,
      tasa: 0.035,
      //iva: 0.16,
      sobretasas: {
        1: 0,
        3: 0.0466,
        6: 0.0751,
        9: 0.1086,
        12: 0.1363,
        18: 0.1999,
      },
      /////////////////////////////
      errorMsg: null,

      /////////////////////////////
      direcciones: [],
      colonias: [],
      direccion: null,
      domicilio: {
        cp: "",
        estado: "",
        municipio: "",
        ciudad: "",
        colonia: "",
        calle: "",
        int: "",
        ext: "",
      },
      /////////////////////////////
      client: {
        id: null,
        email: null,
        nombre: null,
        apellido: null,
        tel: null,
      },
      /////////////////////////////
      promotions: [],
    };
  },
  async created() {
    this.shoppingCart = this.$store.getters.getCarrito();
    if (this.shoppingCart.length > 0) {
      this.profile = this.$decodeToken();
      this.savedAddress();

      if (this.$store.getters.getDomicilio()) {
        this.domicilio = this.$store.getters.getDomicilio();
        this.buscarCP();
        this.domicilio.colonia = this.$store.getters.getDomicilio().colonia;
        this.domicilio.calle = this.$store.getters.getDomicilio().calle;
        this.domicilio.ext = this.$store.getters.getDomicilio().ext;
      }

      if (this.profile) {
        this.client = {
          email: this.profile.email,
          nombre: this.profile.nombre,
          apellido: this.profile.apellido,
          tel: this.profile.tel,
        };
      }else if(this.$store.getters.getInvitado()){
        this.client = this.$store.getters.getInvitado()
      }

      await this.getPromotions();
      this.getResume();
      /*this.timer = setInterval(() => {
      this.profile = this.$decodeToken();
    }, 500);*/
    } else {
      this.$$router.push({ name: "carrito" });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goToResume() {
      let valid = true;
      for (var d in this.client) {
        if (this.client[d] == null || this.client[d] == "") {
          if (this.profile == null && d != "id" && d != "apellido") {
            this.setValidate(d, false);
            valid = false;
          } else if (this.profile != null && d != "apellido") {
            //console.log(d);
            this.setValidate(d, false);
            valid = false;
          }
        } else {
          this.setValidate(d, true);
        }
      }

      for (var d in this.domicilio) {
        if (d != "int") {
          if (
            this.domicilio[d] == null ||
            this.domicilio[d] == "" ||
            typeof this.domicilio[d] == "undefined"
          ) {
            this.setValidate(d, false);
            valid = false;
          } else {
            this.setValidate(d, true);
          }
        }
      }

      if (valid) {
        this.$store.commit("setDomicilio", this.domicilio);
        this.$store.commit("setEnvio", this.envio);

        if (!this.profile) {
          this.$store.commit("setInvitado", this.client);
        } else {
          this.$store.commit("setInvitado", null);
        }

        this.$router.push({ name: "facturacion" });
      } else {
        this.$store.commit("setDomicilio", null);
        this.$store.commit("setEnvio", null);
      }
    },
    getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.shoppingCart[n].price *
          this.shoppingCart[n].cantidad;
        
        
      }

      this.totalfinal =
        this.subtotal +this.envio;
    },
    /*getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.$custom.getHigherPrice(this.shoppingCart[n]) *
          this.shoppingCart[n].cantidad;
        // Si tiene promocion
        if (
          this.promotions.filter((p) => p.codigo == this.shoppingCart[n].codigo)
            .length > 0
        ) {
          //Si es descuento por precio
          if (
            this.promotions.filter(
              (p) => p.codigo == this.shoppingCart[n].codigo
            )[0].precio > 0
          ) {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.promotions.filter(
                (p) => p.codigo == this.shoppingCart[n].codigo
              )[0].precio *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
          //si es descuento por porcentaje
          else {
            this.promocion +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
                this.shoppingCart[n].cantidad;

            this.iva +=
              ((this.$custom.getHigherPrice(this.shoppingCart[n]) *
                (100 -
                  this.promotions.filter(
                    (p) => p.codigo == this.shoppingCart[n].codigo
                  )[0].porcentaje)) /
                100) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
        //no tiene promocion
        else {
          if (this.profile) {
            this.descuento +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
                this.shoppingCart[n].cantidad -
              this.shoppingCart[n][this.profile.precio] *
                this.shoppingCart[n].cantidad;

            this.iva +=
              this.shoppingCart[n][this.profile.precio] *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          } else {
            this.iva +=
              this.$custom.getHigherPrice(this.shoppingCart[n]) *
              this.shoppingCart[n].cantidad *
              this.shoppingCart[n].iva;
          }
        }
      }

      this.totalfinal =
        this.subtotal - this.promocion - this.descuento + this.iva + this.envio;
    },*/
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },
    async buscarCP(change) {
      this.estado = null;
      this.ciudad = null;
      this.municipio = null;

      this.colonias = [];
      try {
        this.getPriceShip();
        let response = await this.$cpostal.get(
           this.domicilio.cp+
            "?token="+this.$copomexToken
        );

        let result = response.data;

        result.forEach((element) => {
          this.colonias.push({
            text: element.response.asentamiento.toUpperCase(),
            value: element.response.asentamiento.toUpperCase(),
            asentamiento: element.response.asentamiento,
            ciudad:
              element.response.ciudad == ""
                ? element.response.municipio
                : element.response.ciudad,
            cp: element.response.cp,
            estado: element.response.estado,
            municipio: element.response.municipio,
            pais: element.response.pais,
            tipo_asentamiento: element.response.tipo_asentamiento,
          });
        });
        if (this.colonias.length > 0) {
          this.domicilio.estado = this.colonias[0].estado;
          this.domicilio.ciudad = this.colonias[0].ciudad;
          this.domicilio.municipio = this.colonias[0].municipio;
        } else {
          this.domicilio.estado = null;
          this.domicilio.ciudad = null;
          this.domicilio.municipio = null;
        }
        if (change) {
          this.domicilio.colonia = null;
        }
      } catch (error) {
        this.colonias = [];
        this.domicilio.estado = null;
        this.domicilio.ciudad = null;
        this.domicilio.municipio = null;
      }
    },
    async getPriceShip() {
      this.canContinue = false;
      try {
        this.envio = 0;
        let response = await this.$api.get(
          "/productos?cp=" + this.domicilio.cp,
          { headers: { method: "getShipPrice" } }
        );

        this.envio = response.data.costo;
        this.canContinue = true;
        this.getResume();
      } catch (error) {
        this.envio = 0;
        this.canContinue = true;
      }
    },
    async savedAddress() {
      if (this.profile != null) {
        try {
          let response = await this.$api.post(
            "/infoUsuario",
            { id: this.profile.id },
            { headers: { method: "infoUsuario" } }
          );

          if (response.data.success) {
            this.direcciones = response.data.data;
          }
        } catch (error) {
          this.errorMsg = "Error al cargar los datos del cliente";
          setTimeout(this.resolveMsg, 3000);
        }
      }
    },
    async seleccionarDireccion() {
      this.domicilio = {
        cp: "",
        //  pais: "",
        estado: "",
        municipio: "",
        ciudad: "",
        colonia: "",
        calle: "",
        int: "",
        ext: "",
      };
      let item = this.direcciones.filter((e) => e.value == this.direccion);
      this.domicilio.cp = item[0].cp;
      await this.buscarCP();
      //this.domicilio = {
      //cp: item[0].cp,
      //estado: item[0].estado,
      //municipio: item[0].municipio,
      //ciudad: item[0].ciudad,

      this.domicilio.colonia = item[0].colonia;
      this.domicilio.calle = item[0].calle;
      this.domicilio.int = item[0].int;
      this.domicilio.ext = item[0].ext;
      //};

      this.direccion = null;
    },
    /////////////////////////////////////////////////////////////////////
    setValidate(ref, success) {
      if (this.$refs[ref]) {
        if (success) {
          this.$refs[ref].$el.classList.add("has-success");
          this.$refs[ref].$el.classList.remove("has-danger");
        } else {
          this.$refs[ref].$el.classList.remove("has-success");
          this.$refs[ref].$el.classList.add("has-danger");
        }
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
</style>