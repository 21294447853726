import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from './apis/web';
import cpostal from './apis/sepomex';
import skydrop from './apis/skydrop';
import coppel from './apis/coppel'
import custom from './custom';
import NowUiKit from './plugins/now-ui-kit';


import {
  BAvatar,
  BAvatarGroup,
  BImg,
  BSkeleton,
  BCollapse,
  BButton,
  BCard,
  BCardText,
  BCardBody,
  BCardHeader,
  BCardFooter,
  BFormCheckbox,
  BFormCheckboxGroup,
  BootstrapVue,
  BootstrapVueIcons,
  BSpinner,
  ToastPlugin,
  VBToggle
} from 'bootstrap-vue';
import InfiniteSlideBar from "vue-infinite-slide-bar";
import VueJWT from 'vuejs-jwt';
import Magnifier from 'vuejs-magnifier'

Vue.use(Magnifier)
Vue.use(NowUiKit);
Vue.use(VueJWT, { "signKey": "c,3Y>sS;8}5[ubec", "store": "localStorage", "keyName": "abv-app" })

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$copomexToken="928fbdb3-1f6d-4c76-93ab-d87a415bcddf";
//Vue.prototype.$copomexToken="pruebas";
Vue.prototype.$cpostal = cpostal;
Vue.prototype.$coppel = coppel;
Vue.prototype.$custom = custom;
Vue.prototype.$skydrop = skydrop;

Vue.prototype.$decodeToken = function (token, sign) {

  if (typeof token == "undefined" || typeof sign == "undefined") {
    let storageToken = store.getters.getToken();

    if (storageToken == null) {
      return null;
    }
    else {

      try {
        var decode = this.$jwt.decode(storageToken)
        if (decode == null){
          store.commit("setToken", null);
        }
        return decode
      } catch (error) {
        store.commit("setToken", null);
        return null;
      }
    }


  } else {
    try {
      return this.$jwt.decode(token, sign)
    } catch (error) {
      return null;
    }
  }


}


 


Vue.component("InfiniteSlideBar", InfiniteSlideBar);
Vue.component('b-avatar', BAvatar)
Vue.component('b-avatar-group', BAvatarGroup)
Vue.component('b-img', BImg)
Vue.component('b-skeleton-img', BSkeleton)
Vue.component('b-button', BButton)
Vue.component('b-collapse', BCollapse)
Vue.component('b-card', BCard)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-text', BCardText)
Vue.component('b-card-footer', BCardFooter)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.directive('b-toggle', VBToggle)
Vue.component('b-spinner', BSpinner)
Vue.use(BootstrapVueIcons)
Vue.use(BootstrapVue)
Vue.use(ToastPlugin);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
