var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mostselled.length>0)?_c('div',{staticClass:"sectionMision section-about-us",staticStyle:{"background-color":"rgba(163, 161, 158, 0.1)"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"separator separator-primary"}),_vm._m(0),_c('div',{staticClass:"row carousel-full"},[_c('carousel',{attrs:{"perPageCustom":[
          [320, 1],
          [375, 1],
          [425, 2],
          [768, 3],
          [1024, 4] ],"autoplay":true,"paginationActiveColor":"#001489","paginationColor":"#A3A19E","easing":"linear","autoplayHoverPause":true,"paginationSize":Number(5)}},_vm._l((_vm.mostselled),function(ms,$index){return _c('slide',{key:$index},[_c('router-link',{attrs:{"to":{ name: 'details', params: { id: ms.model } }}},[_c('center',{staticStyle:{"word-break":"break-all"}},[(ms.images == 'url')?_c('img',{staticClass:"producto",attrs:{"src":_vm.noimage}}):_c('img',{staticClass:"producto",attrs:{"src":ms.images.split(',')[0]}}),_c('label',{staticClass:"mx-1",staticStyle:{"font-weight":"bold","word-break":"break-all"}},[_vm._v(" "+_vm._s(ms.title)+" ")]),_c('br'),(
                  _vm.promotions.filter(function (p) { return p.codigo == ms.codigo; }).length > 0
                )?_c('div',[_c('h4',{staticClass:"mb-0",style:('text-decoration: line-through; color: red')},[_vm._v(" "+_vm._s(_vm.$custom.Formatter.format( _vm.$custom.getHigherPrice(ms) * Number(1 + ms.iva) ))+" ")]),_c('h4',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(_vm.promotions.filter(function (p) { return p.codigo == _vm.n.codigo; }).length > 0 ? _vm.promotions.filter(function (p) { return p.codigo == _vm.n.codigo; })[0] .precio > 0 ? _vm.$custom.Formatter.format( _vm.promotions.filter(function (p) { return p.codigo == ms.codigo; })[0] .precio * Number(1 + ms.iva) ) : _vm.$custom.Formatter.format( _vm.$custom.getHigherPrice(ms) * ((100 - _vm.promotions.filter( function (p) { return p.codigo == _vm.n.codigo; } )[0].porcentaje) / 100) * Number(1 + ms.iva) ) : " No tiene promoción")+" ")])]):(_vm.profile == null)?_c('label',[_vm._v(_vm._s(_vm.$custom.Formatter.format( ms.price )))]):(_vm.profile.precio == null)?_c('label',[_vm._v(" "+_vm._s(_vm.$custom.Formatter.format( _vm.$custom.getHigherPrice(ms) * Number(1 + ms.iva) ))+" ")]):_c('div',[_c('label',{style:(ms[_vm.profile.precio] < _vm.$custom.getHigherPrice(ms)
                      ? 'text-decoration: line-through; color: red'
                      : '')},[_vm._v(" "+_vm._s(_vm.$custom.Formatter.format( _vm.$custom.getHigherPrice(ms) * Number(1 + ms.iva) ))+" ")]),_c('br'),(ms[_vm.profile.precio] < _vm.$custom.getHigherPrice(ms))?_c('label',[_vm._v(" "+_vm._s(_vm.$custom.Formatter.format( ms[_vm.profile.precio] * Number(1 + ms.iva) ))+" ")]):_vm._e()])])],1)],1)}),1)],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v("Más vendidos")])])])}]

export { render, staticRenderFns }