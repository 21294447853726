<template>
  <div class="clear-filter">
    <carousel
      :perPage="1"
      :autoplay="true"
      paginationActiveColor="#0f0f0f"
      paginationColor="#A3A19E"
      easing="linear"
      :autoplayHoverPause="true"
      paginationPosition="bottom-overlay"
      :loop="true"
      :centerMode="true"
      :adjustableHeight="true"
      style="max-height:92vh"
    >
      <slide v-for="(ms, $index) in banners" :key="$index">
        <img class="" :src="ms.img"  style="max-height: 92vh;min-height: 92vh; width:100%;" />
      </slide>
    </carousel>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
const PUBLIC_PATH = process.env.BASE_URL;

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      logo: `${PUBLIC_PATH}brand/logo_conti.png`,
      banners: [],
    };
  },
  created() {
    this.getImages();
  },
  methods: {
    getImages: async function () {
      try {
        let response = await this.$api.get("/personalization/covers");
        this.banners = response.data.data;
      } catch (error) {
        this.banners = [];
      }
    },
  },
};
</script>
<style scoped>
.carousel-full {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
</style>
  
