<template>
  <div
    v-if="mostselled.length>0"
    class="sectionMision section-about-us"
    style="background-color: rgba(163, 161, 158, 0.1)"
  >
    <div class="container">
      <div class="separator separator-primary"></div>
      <div class="text-center">
        <div class="container">
          <h2 class="title" style="">Más vendidos</h2>
        </div>
      </div>
      <div class="row carousel-full">
        <carousel
          :perPageCustom="[
            [320, 1],
            [375, 1],
            [425, 2],
            [768, 3],
            [1024, 4],
          ]"
          :autoplay="true"
          paginationActiveColor="#001489"
          paginationColor="#A3A19E"
          easing="linear"
          :autoplayHoverPause="true"
          :paginationSize="Number(5)"
        >
          <slide v-for="(ms, $index) in mostselled" :key="$index">
            <router-link :to="{ name: 'details', params: { id: ms.model } }">
              <center style="word-break: break-all">
                <img
                  v-if="ms.images == 'url'"
                  class="producto"
                  :src="noimage"
                />
                <img v-else class="producto" :src="ms.images.split(',')[0]" />

                <label
                  class="mx-1"
                  style="font-weight: bold; word-break: break-all"
                >
                  {{ ms.title }}
                </label>
                <br />
                <!--EN CASO DE TENER PROMOCION-->
                <div
                  v-if="
                    promotions.filter((p) => p.codigo == ms.codigo).length > 0
                  "
                >
                 
                  <h4
                    class="mb-0"
                    :style="'text-decoration: line-through; color: red'"
                  >
                    {{
                      $custom.Formatter.format(
                        $custom.getHigherPrice(ms) * Number(1 + ms.iva)
                      )
                    }}
                  </h4>

                  <h4 class="mt-0">
                    {{
                      promotions.filter((p) => p.codigo == n.codigo).length > 0
                        ? promotions.filter((p) => p.codigo == n.codigo)[0]
                            .precio > 0
                          ? $custom.Formatter.format(
                              promotions.filter((p) => p.codigo == ms.codigo)[0]
                                .precio * Number(1 + ms.iva)
                            )
                          : $custom.Formatter.format(
                              $custom.getHigherPrice(ms) *
                                ((100 -
                                  promotions.filter(
                                    (p) => p.codigo == n.codigo
                                  )[0].porcentaje) /
                                  100) *
                                Number(1 + ms.iva)
                            )
                        : " No tiene promoción"
                    }}
                  </h4>
                </div>
                <!-- Precio mayor si no hay sesion-->
                <label v-else-if="profile == null">{{
                  $custom.Formatter.format(
                    ms.price
                  )
                }}</label>

                <!-- Precio mayor hay session y no tiene precio especial-->
                <label v-else-if="profile.precio == null">
                  {{
                    $custom.Formatter.format(
                      $custom.getHigherPrice(ms) * Number(1 + ms.iva)
                    )
                  }}
                </label>
                <!-- Tiene sesión y precio especial-->
                <div v-else>
                  <label
                    :style="
                      ms[profile.precio] < $custom.getHigherPrice(ms)
                        ? 'text-decoration: line-through; color: red'
                        : ''
                    "
                  >
                    {{
                      $custom.Formatter.format(
                        $custom.getHigherPrice(ms) * Number(1 + ms.iva)
                      )
                    }}
                  </label>
                  <br />
                  <!-- El precio especial no es el precio mayor -->
                  <label v-if="ms[profile.precio] < $custom.getHigherPrice(ms)">
                    {{
                      $custom.Formatter.format(
                        ms[profile.precio] * Number(1 + ms.iva)
                      )
                    }}
                  </label>
                </div>
              </center>
            </router-link>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";

const PUBLIC_PATH = process.env.BASE_URL;

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      timer: null,
      profile: null,
      mostselled: [],
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      promotions: [],
    };
  },
  created() {
    this.profile = this.$decodeToken();
    this.timer = setInterval(() => {
      this.profile = this.$decodeToken();
    }, 500);
    this.getMostSelled();
    this.getPromotions();
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },

  methods: {
    getMostSelled: async function () {
      try {
        let response = await this.$api.get("/personalization/mostSelled");
        this.mostselled = response.data.data;
      } catch (error) {
        this.mostSelled = [];
      }
    },
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },
    isPromotion: async function (codigo) {
      return this.promotions.filter((e) => {
        return e.codigo == codigo;
      });
    },
  },
};
</script>
<style scoped>
.producto {
  width: auto;
  height: 250px;
}
.carousel-full {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
</style>
  
