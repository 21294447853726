<template>
  <div>
    <div class="clear-filter" filter-color="orange">
      <div class="page-header-image" style="background-image: url('brand/back.jpg')"></div>

      <div class="container">
        <card id="card-container">
          <template>
            <tabs
              type="primary"
              tabNavWrapperClasses="col-md-3"
              tabContentClasses="col-md-9"
              vertical
              square
              class="row"
            >
              <tab-pane>
                <span slot="label">
                  <i class="now-ui-icons objects_umbrella-13"></i> Datos de envío y Facturacion
                </span>
                
                <div class="row">
                  <div class="col-md-5">
                    <p>Bienvenido {{this.usuario.nombre+" "+this.usuario.apellido}}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="text" class="form-control" placeholder="Buscar...">
                    
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-primary btn-round" type="button">
                      <i class="now-ui-icons ui-1_zoom-bold"></i> Buscar
                    </button>
                  </div>
                  
                </div>

                
                  <form>
                      <div class="row">
                      <div class="col-md-4"> 
            <div class="form-group">
              <label for="id1">Nombre</label>
              <input type="text" class="form-control" id="id1" placeholder="Nombre...">
            </div>
            <div class="form-group">
              <label for="catalogo1">Apellido</label>
              <input type="text" class="form-control" id="catalogo1" placeholder="Apellidos...">
            </div>
            <div class="form-group">
              <label for="stock1">Contraseña nueva</label>
              <input type="password" class="form-control" id="stock1" placeholder="Contraseña de 8 a 16 caracteres">
            </div>
            <div class="form-group">
              <label for="linea1">Confirmar nueva contraseña</label>
              <input type="password" class="form-control" id="linea1" placeholder="Contraseña de 8 a 16 caracteres">
            </div>
            <div class="form-group">
              <label for="nombre1">RFC</label>
              <input type="text" class="form-control" id="exampleInputName1" placeholder="">
            </div>
            <div class="form-group">
              <label for="piezas1">Piezas</label>
              <input type="number" class="form-control" id="piezas1" placeholder="Unidades por paquete...">
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="form-group">
              <label for="costo1">Costo</label>
              <input type="number" class="form-control" id="costo1" placeholder="Costo del producto...">
            </div>
            <div class="form-group">
              <label for="precio1">Precio de venta</label>
              <input type="number" class="form-control" id="precio1" placeholder="Precio de venta...">
            </div>
            <div class="form-group">
              <label for="proveedor1">Proveedor</label>
              <select class="form-control" id="proveedor1">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="codigo1">Codigo de barras</label>
              <input type="text" class="form-control" id="codigo1" aria-describedby="emailHelp" placeholder="Codigo...">
            </div>
            <div class="form-group">
              <label for="descripcion1">Descripción</label>
              
              <textarea class="form-control" id="descripcion1" rows="4" placeholder="Descripcion del producto..."></textarea>
            </div>
            
          </div>
          </div>
                  </form>

                  
                
                
               

              </tab-pane>

              <tab-pane>
                <span slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i> Pedidos
                </span>
                
              </tab-pane>
            </tabs>
          </template>
        </card>
      </div>

    <modal
      :show.sync="modals.mini"
      :show-close="false"
      header-classes="justify-content-center"
      type="mini"
    >
      <div slot="header" class="d-flex justify-content-center align-items-center">
        <!--<i class="fas fa-sign-in-alt"></i>-->
        Confirmación
      </div>
      <p>Desea eliminar el producto de la tienda</p>
      <template slot="footer">
        <n-button  class="btn btn-primary" link>Cancelar</n-button>
        <n-button  class="btn btn-primary" link @click.native="modals.mini=false">Aceptar</n-button>
      </template>
    </modal>


    </div>
  </div>
</template>
<script>
import { Tabs, TabPane, Card, Button, Modal, Pagination} from "@/components";
import UploadImage from 'vue-upload-image';
const PUBLIC_PATH = process.env.BASE_URL;

export default {
  name: "profile",
  bodyClass: "profile-page",

  props: {
    datos: Array,
    display: Boolean
  },
  components: {
    Tabs,
    TabPane,
    Card,
    UploadImage,
    [Pagination.name]:Pagination,
    [Button.name]: Button,
    [Modal.name]:Modal
  },
   created() {
    
    this.usuario.nombre = this.$decodeToken().nombre;
    this.usuario.apellido = this.$decodeToken().apellido;
    
  },
  data: () => {
    return {
      modals: {
        mini: false,
        mod:false,
        large: false
      },
      forms:{
        create:{
          url:"#",
          confirm:"Arrastra y suelta aquí los elementos para cargar"
        }
      },
      edit:false,
      productos:[

      ],
     
      usuario: {
        nombre: "",
        apellido: ""
      },
      paginaSel:1,
      mini: true,
      tabText: {
        p11: "...",
        p12: "...",
        p21: "...",
        p22: "...",
        p31: "...",
        p32: "..."
      },
      imagen: `${PUBLIC_PATH}brand/prod.png`,
      imagendos: `${PUBLIC_PATH}brand/int.png`,
      publicPath: PUBLIC_PATH,
      imgSrc: [
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h5b/h84/8900860870686/Images_Digital_Assets/THQB1130_front.jpg",
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/hb8/h32/8799637995550/Images_Original_Circuit%20Breakers/THQB11XX.jpg",
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h94/hd9/8900861263902/Images_Digital_Assets/THQB21100_front.jpg",
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/hf2/hed/8900861853726/Images_Digital_Assets/THQB2120_front.jpg",
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h6a/hbb/8900298571806/Images_Digital_front/THQB2115_front.jpg",
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/hf0/hf0/8900861919262/Images_Digital_Assets/THQB2120_left.jpg",
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h45/h7e/8799620988958/Images_Original_Circuit%20Breakers/THQB2XXX.jpg",
        "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/hc3/hb8/8900298604574/Images_Digital_front/THQB2140_front.jpg"
      ]
    };
  },
  methods: {
    start: function() {},
    editMode: function() {
      this.edit = !this.edit;
    },
    deleteProduct: function() {
      
    },
    addProduct: function() {
      
    },
    mostrarPagina:function(pagina){
      this.paginaSel = pagina;
      this.paginaproductos = this.productos.slice((pagina-1)*10, (pagina*10)-1);
      
    },
    uploadImageSuccess: function(result){
      result[0] // FormData
      result[1] // response
    },
    uploadImageLoaded: function(image){
      image.name || image.file 
    },
    uploadImageClicked: function(image){
      image.name || image.file;
      
    },
    uploadImageRemoved: function(image){
      image.name || image.file 
    },
    uploadImageSubmit: function(images){

    },
    uploadImageAttempt:function(FormData){

    },
    uploadImageFailure:function(FormData,Response){
      
    },
  

  },
  
};
</script>
<style scoped>
#card-container {
  margin-top: 100px;
  min-height: 500px;
  margin-bottom: 60px;
}
.card {
  margin-top: 50px;
  margin-left: 20px;
}
.delete-button {
  margin-top: -50px;
  margin-left: 180px;
}

#paginacion{
  margin-top: 20px;
}

.clientsbar {
  display: inline-block;
  width: 3390px;
  overflow: hidden;
}
.clientesimg {
  height: 100px;
  margin: 30px 30px 100px 30px;
}
.clearfix {
  overflow: auto;
}
</style>
