
<template>
  <div>
    <infinite-slide-bar
      :barStyle="{ background: '#fff', padding: '5px 0' }"
      :duration="'20s'"
    >
    </infinite-slide-bar>
    <section id="actions" style="margin-top: 100px; margin-bottom: 80px">
      <div>
        <div class="container">
          <h1>Mis compras</h1>

          <b-table
            show-empty
            small
            stacked="md"
            :items="pedidos"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            empty-text="Sin pedidos registrados"
          >
            <template v-slot:cell(registro)="row">
              {{
                row.item.registro.split("T")[0] +
                " " +
                row.item.registro.split("T")[1].split(".")[0]
              }}
            </template>

            <template v-slot:cell(importe)="row">
              {{ $custom.Formatter.format(row.item.importe) }}
            </template>

            <template v-slot:cell(pdf)="row">
              <n-button
                v-if="row.item.url != null"
                size="sm"
               
              >
                <!--Descargar pdf-->
                <i class="far fa-file-pdf"></i> PDF
              </n-button>
              <n-button
                v-if="row.item.url != null"
                size="sm"
                
              >
                <!--Descargar xml-->
                <i class="far fa-file-excel"></i> XML
              </n-button>
            </template>
            <template v-slot:cell(actions)="row">
              <n-button
                size="sm"
                @click="row.toggleDetails"
                :type="row.detailsShowing ? 'default' : 'primary'"
              >
                {{ row.detailsShowing ? "Ocultar" : "Detalles" }}
              </n-button>
            </template>
            <template v-slot:row-details="row">
              <card plain>
                <h3>Estado del pedido:</h3>
                <!------------------------------------------------------>
                <stepper
                  :options="{
                    headers: [
                      { title: 'Pedido Recibido' },
                      { title: 'Pedidio en Prepraración' },
                      { title: 'Pedido Enviado' },
                      { title: 'Pedido Entregado' },
                    ],
                  }"
                  :currentPosition="
                    row.item.estatus == 'RECIBIDO'
                      ? 0
                      : row.item.estatus == 'ENVIADO'
                      ? 2
                      : row.item.estatus == 'ENTREGADO'
                      ? 3
                      : 1
                  "
                >
                </stepper>
                <div class="row">
                  <div class="col-md-6">
                    <b-table
                      show-empty
                      small
                      stacked="md"
                      :items="row.item.carrito"
                      :fields="[
                        {
                          key: 'cantidad',
                          label: 'Cantidad',
                          sortable: true,
                          sortDirection: 'desc',
                        },
                        {
                          key: 'descripcioncorta',
                          label: 'Producto',
                          sortable: true,
                          sortDirection: 'desc',
                        },
                        {
                          key: 'precio',
                          label: 'Precio Unit',
                          sortable: true,
                          sortDirection: 'desc',
                        },
                        {
                          key: 'importe',
                          label: 'Importe',
                          sortable: true,
                          sortDirection: 'desc',
                        },
                      ]"
                      empty-text="Lista de árticulos no disponible"
                    >
                      <template v-slot:cell(precio)="article">
                        {{ $custom.Formatter.format(article.item.precio) }}
                      </template>
                      <template v-slot:cell(importe)="article">
                        {{ $custom.Formatter.format(article.item.importe) }}
                      </template>
                    </b-table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Subtotal: </label>
                          {{ $custom.Formatter.format(row.item.subtotal) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Descuento: </label>
                          {{ $custom.Formatter.format(row.item.descuento) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Total: </label>
                          {{ $custom.Formatter.format(row.item.importe) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-md-6">
                    <h4 class="mb-1">Domicilio de entrega:</h4>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">CP: </label>
                          {{ row.item.entcp }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Estado: </label>
                          {{ row.item.entestado }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Ciudad: </label>
                          {{ row.item.entlocalidad }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Colonia: </label>
                          {{ row.item.entcolonia }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Dirección: </label>
                          {{ row.item.entcalle }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="row.item.factura == 'SI'">
                    <h4 class="mb-1">Facturación:</h4>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">RFC: </label>
                          {{ row.item.rfc }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">USO CFDI: </label>
                          {{ row.item.usocfdi }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">CP: </label>
                          {{ row.item.cp }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Estado: </label>
                          {{ row.item.estado }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Ciudad: </label>
                          {{ row.item.localidad }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Colonia: </label>
                          {{ row.item.colonia }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="mb-0">
                          <label class="text-primary h6">Dirección: </label>
                          {{ row.item.calle }}
                          {{
                            row.item.noexterior
                              ? " #" + row.item.noexterior
                              : ""
                          }}
                          {{ row.item.nointerior }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
              <!------------------------------------------------------>
            </template>
          </b-table>
          <div class="col-sm-12 col-md-6 my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="pedidos.length"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Button, Pagination, Card } from "@/components";

import { BTable, BPagination } from "bootstrap-vue";
import Stepper from "../../components/Stepper/Stepper";

export default {
  name: "profile",
  bodyClass: "profile-page",
  components: {
    BPagination,
    BTable,
    Card,
    [Pagination.name]: Pagination,
    [Button.name]: Button,
    Stepper,
  },
  created() {
    if (this.$decodeToken() == null) {
      this.$router.push({ name: "login" });
    } else {
      this.$api
        .post(
          "/pedidos",
          { id: this.$decodeToken().id },
          { headers: { method: "listarCliente" } }
        )
        .then((response) => {
          this.pedidos = response.data.data;
        })
        .catch((error) => {});
    }
  },
  data: () => {
    return {
      successMsg: null,
      errorMsg: null,
      pedidos: [],
      fields: [
        {
          key: "folioweb",
          label: "# Pedido",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "registro",
          label: "Fecha de compra",
          sortDirection: "desc",
          sortable: true,
        },
        {
          key: "importe",
          label: "Total",
          sortable: true,
          class: "text-center",
        },
        {
          key: "estatus",
          label: "Status",
          sortable: true,
          class: "text-center",
        },

        { key: "pdf", label: "PDF Factura" },
        { key: "xml", label: "XML Factura" },

        { key: "actions", label: "Acciones" },
      ],
      currentPage: 1,
      perPage: 10,
    };
  },
  methods: {
  },
};
</script>
<style scoped>
</style>