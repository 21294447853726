import Vue from 'vue';
import Router from 'vue-router';

//Layout
import Layout from '@/layout/Layout.vue'

//OutSide
import Index from './pages/Landing/Index.vue';
import Contactanos from './pages/Contactanos/Contactanos.vue';
import Nosotros from './pages/Nosotros/Nosotros.vue';
import Carrito from './pages/Carrito/Carrito.vue';
import Envio from './pages/Carrito/Envio.vue';
import Facturacion from './pages/Carrito/Facturacion.vue';
import Tracing from './pages/Cliente/Seguimiento.vue';
import Login from './pages/Login.vue';


//Cliente
import Cliente from './pages/Cliente/Cliente.vue';
import Pedidos from './pages/Cliente/Pedidos.vue';

//Settings
import Perfil from './pages/Settings/Perfil.vue';
import Dashboard from './pages/Settings/Dashboard.vue';

//Store
import Products from './pages/Store/Productos.vue';
import Details from './pages/Store/Detalles.vue';

//Payment
import Resume from './pages/Payment/Resume.vue';
import Netpay from './pages/Payment/Netpay.vue'

//Politicas
import Devoluciones from './pages/Politicas/Devoluciones'
import Envios from './pages/Politicas/Envios'
import Privacidad from './pages/Politicas/Privacidad'
import TyC from './pages/Politicas/TyC'


Vue.use(Router);

var router = new Router({
  linkExactActiveClass: 'active',
  mode: "history",
  routes: [
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '',
          name: 'index',
          component: Index
        },
        {
          path: 'contactanos',
          name: 'contactanos',
          component: Contactanos
        },
        {
          path: 'nosotros',
          name: 'nosotros',
          component: Nosotros
        },
        {
          path: 'carrito',
          name: 'carrito',
          component: Carrito
        },
        {
          path: 'envio',
          name: 'envio',
          component: Envio
        },
        {
          path: 'facturacion',
          name: 'facturacion',
          component: Facturacion
        },
      ]
    },
    {
      path: '/productos',
      component: Layout,
      children: [
        {
          path: '',
          name: 'productos',
          component: Products
        },
        {
          path: ':id',
          name: 'details',
          component: Details
        }
      ]
    },
    {
      path: '/seguimiento',
      component: Layout,
      children: [
        {
          path: ':id',
          name: 'tracing',
          component: Tracing
        }
      ]
    },
    {
      path: '/pago',
      component: Layout,
      children: [
        {
          path: '',
          name: 'resume',
          component: Resume
        },
        {
          path: 'card',
          name: 'netpay',
          component: Netpay
        }
      ]
    },
    {
      path: '/politicas',
      component: Layout,
      children: [
        {
          path: 'devoluciones',
          name: 'devoluciones',
          component: Devoluciones
        },
        {
          path: 'envios',
          name: 'envios',
          component: Envios
        },
        {
          path: 'privacidad',
          name: 'privacidad',
          component: Privacidad
        },
        {
          path: 'terminosycondiciones',
          name: 'tyc',
          component: TyC
        }
      ]
    },
    {
      path: '/cliente',
      component: Layout,
      children: [
        {
          path: '',
          name: 'cliente',
          component: Cliente
        },
        {
          path: 'pedidos',
          name: 'pedidos',
          component: Pedidos
        },
      ]
    },
    {
      path: '/dashboard',
      component: Layout,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: Dashboard
        },
        {
          path: 'perfil',
          name: 'perfil',
          component: Perfil
        },
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '*',
      redirect: '/'
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
