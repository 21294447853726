<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template slot-scope="{}">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img :src="logo" alt class="logo" />
      </router-link>
    </template>

    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link
          data-placement="bottom"
          rel="tooltip"
          title
          class="nav-link text-white"
          :to="{ name: 'index' }"
        >
          <p>Inicio</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          data-placement="bottom"
          rel="tooltip"
          title
          class="nav-link text-white"
          :to="{ name: 'productos' }"
        >
          <p>Productos</p>
        </router-link>
      </li>

      <!--<li class="nav-item">
        <router-link
          data-placement="bottom"
          rel="tooltip"
          title
          class="nav-link text-white"
          :to="{ name: 'index' }"
        >
          <p>Promociones</p>
        </router-link>
      </li>-->

      <li class="nav-item">
        <router-link
          data-placement="bottom"
          rel="tooltip"
          title
          class="nav-link text-white"
          :to="{ name: 'contactanos' }"
        >
          <p>Contáctanos</p>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          data-placement="bottom"
          rel="tooltip"
          title
          class="nav-link text-white"
          :to="{ name: 'nosotros' }"
        >
          <p>Nosotros</p>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          data-placement="bottom"
          rel="tooltip"
          title="Ver carrito"
          class="nav-link text-white"
          :to="{ name: 'carrito' }"
        >
          <i class="now-ui-icons shopping_cart-simple"></i>
          <badge type="default" style="font-weight: bold">{{
            countItems
          }}</badge>
        </router-link>
      </li>

      <!--<li>
        <router-link
          v-if="empleado == null"
          data-placement="bottom"
          rel="tooltip"
          title="Ingresa con tu usuario"
          class="nav-link text-white"
          style="display: flex"
          :to="{ name: 'login' }"
        >
          <p>Login</p>

          <i class="now-ui-icons users_circle-08"></i>

          <!- -p>Login</p- ->
        </router-link>

        <router-link
          v-if="empleado && empleado.rol == 'admin_ecommerce'"
          data-placement="bottom"
          rel="tooltip"
          title="Ir al dashboard"
          class="nav-link text-white"
          style="display: flex"
          :to="{ name: 'dashboard' }"
        >
          <p>{{ empleado.nombre }}</p>

          <i class="now-ui-icons users_circle-08"></i>
        </router-link>
        <router-link
          v-if="empleado && empleado.rol != 'admin_ecommerce'"
          data-placement="bottom"
          rel="tooltip"
          title="Ver mi perfil"
          class="nav-link text-white"
          style="display: flex"
          :to="{ name: 'cliente' }"
        >
          <p>{{ empleado.nombre }}</p>

          <i class="now-ui-icons users_circle-08"></i>
        </router-link>
      </li>
      <li>
        <drop-down v-if="empleado != null">
          <ul id="lista">
            <li v-if="empleado.rol == 'admin_ecommerce'">
              <router-link
                data-placement="bottom"
                rel="tooltip"
                title
                class="nav-link-drop"
                :to="{ name: 'dashboard' }"
              >
                <p style="color: black">Dashboard</p>
              </router-link>
            </li>
             <li v-if="empleado.rol == 'cliente_ecommerce'">
              <router-link
                data-placement="bottom"
                rel="tooltip"
                title
                class="nav-link-drop"
                :to="{ name: 'cliente' }"
              >
                <p style="color: black">Cliente {{empleado.id}}</p>
              </router-link>
            </li>
            <li v-if="empleado.rol == 'cliente_ecommerce'">
              <router-link
                data-placement="bottom"
                rel="tooltip"
                title
                class="nav-link-drop"
                :to="{ name: 'pedidos' }"
              >
                <p style="color: black">Compras</p>
              </router-link>
            </li>
            <li v-if="empleado.rol == 'cliente_ecommerce'">
              <router-link
                data-placement="bottom"
                rel="tooltip"
                title
                class="nav-link-drop"
                :to="{ name: 'cliente' }"
              >
                <p style="color: black">Perfil</p>
              </router-link>
            </li>
            <li @click="logout">Cerrar sesión</li>
          </ul>
        </drop-down>
      </li>-->
    </template>
  </navbar>
</template>

<script>
const PUBLIC_PATH = process.env.BASE_URL;

import {
  DropDown,
  NavbarToggleButton,
  Navbar,
  NavLink,
  Badge,
  FormGroupInput as FgInput,
} from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover,
    [Badge.name]: Badge,
    FgInput,
  },
  data: () => {
    return {
      empleado: null,
      timer: null,
      totalCarrito: 0,
      logo: `${PUBLIC_PATH}brand/logo_blanco.png`,
    };
  },
  created() {
    this.empleado = this.$decodeToken();
    this.timer = setInterval(() => {
      this.empleado = this.$decodeToken();
    }, 500);
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  methods: {
    logout: function () {
      this.$store.commit("setToken", null);
      if (
        this.$route.name == "cliente" ||
        this.$route.name == "pedidos" ||
        this.$route.name == "dashboard"
      ) {
        this.$router.go({ name: "index" });
      }
    },
  },
  computed: {
    countItems: function () {
      let cantidad = 0;
      let list = this.$store.getters.getCarrito();
      if (list != null) {
        list.forEach((e) => (cantidad += Number(e.cantidad)));
      }
      return cantidad;
    },
  },
};
</script>

<style scoped>
.logo {
  height: 50px;
}

nav-link {
  color: white;
}

.nav-link-drop {
  color: #000000;
}

.dropdown-item {
  text-align: center;
  background-color: #ffffff00 !important;
  padding: 0.25rem 0rem 0.25rem 2rem;
}

.menu {
  background-color: #ffffff00 !important;
  font-size: 11px;
  padding-left: 0px !important;
  color: #ffffff !important;
}
ul {
  list-style-type: none;
}
.dropdown-menu:before {
  left: 110px !important;
}

#drop-menu {
  transform: translate3d(100px, 0px, 0);
}
a {
  color: #000000;
}

.navbar p {
  font-weight: bold;
  font-size: 10pt;
}
</style>
