<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <card class="mt-5">
            <div class="row">
              <div class="col col-md-12">
                <center>
                  <magnifier
                    :src="example == 'url' ? noimage : example"
                    :glass-width="200"
                    :glass-height="200"
                    :width="365"
                    :height="365"
                    :zoom-level="3"
                  />
                </center>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <carousel
                  :perPageCustom="[
                    [380, 5],
                    [668, 5],
                  ]"
                  :autoplayHoverPause="true"
                  :autoplay="true"
                  :loop="true"
                  paginationActiveColor="#001489"
                  paginationColor="#A3A19E"
                  easing="ease-in-out"
                  :paginationSize="Number(5)"
                  :autoplayTimeout="8000"
                >
                  <slide v-for="(image, i) in item.images.split(',')" :key="i">
                    <img
                      class="image"
                      :src="image == 'url' ? noimage : image"
                      @click="onClick(image == 'url' ? noimage : image)"
                    />
                  </slide>
                </carousel>
              </div>
            </div>
          </card>
        </div>
        <div class="col col-md-6">
          <card plain class="mt-0 mb-0 pb-0" cardBodyClassess="mb-0 pb-0">
            <div class="card-body mb-0 pb-0">
              <center class="my-2 text-primary" v-if="item.title">
                <h4>{{ item.title }}</h4>
              </center>
              <center class="mt-3" v-if="promotions">
                <!--EN CASO DE TENER PROMOCION-->
                <div
                  v-if="
                    promotions.filter((p) => p.model == item.model).length > 0
                  "
                >
                  <h4
                    class="mb-0"
                    :style="'text-decoration: line-through; color: red'"
                  >
                    {{
                      $custom.Formatter.format(
                        $custom.getHigherPrice(item)
                      )
                    }}
                  </h4>

                  <h4 class="mt-0">
                    {{
                      promotions.filter((p) => p.model == item.model).length >
                      0
                        ? promotions.filter((p) => p.model == item.model)[0]
                            .precio > 0
                          ? $custom.Formatter.format(
                              promotions.filter(
                                (p) => p.model == item.model
                              )[0].precio
                            )
                          : $custom.Formatter.format(
                              $custom.getHigherPrice(item) *
                                ((100 -
                                  promotions.filter(
                                    (p) => p.model == item.model
                                  )[0].porcentaje) /
                                  100) *
                                Number(1 + item.iva)
                            )
                        : " No tiene promoción"
                    }}
                  </h4>
                </div>
                <!-- Precio mayor si no hay sesion-->
                <label v-else-if="profile == null">{{
                  $custom.Formatter.format(
                   item.price
                  )
                }}</label>

                <!-- Precio mayor hay session y no tiene precio especial-->
                <label v-else-if="profile.precio == null">
                  {{
                    $custom.Formatter.format(
                      $custom.getHigherPrice(item)
                    )
                  }}
                </label>
                <!-- Tiene sesión y precio especial-->
                <div v-else>
                  <label
                    :style="
                      item[profile.precio] < $custom.getHigherPrice(item)
                        ? 'text-decoration: line-through; color: red'
                        : ''
                    "
                  >
                    {{
                      $custom.Formatter.format(
                        $custom.getHigherPrice(item)
                      )
                    }}
                  </label>
                  <br />
                  <!-- El precio especial no es el precio mayor -->
                  <label
                    v-if="item[profile.precio] < $custom.getHigherPrice(item)"
                  >
                    {{
                      $custom.Formatter.format(
                        item[profile.precio]
                      )
                    }}
                  </label>
                </div>
              </center>
              <div class="row justify-content-center mt-5">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6 mr-0 pr-0">
                      <fg-input
                        ref="selectedCount"
                        v-model="cantidad"
                        placeholder="cantidad"
                        class="col-md-9 offset-md-3 mr-0 pr-0"
                        value="1"
                        type="number"
                        min="1"
                        :max="existencia"
                      ></fg-input>
                    </div>

                    <div class="col-md-6 ml-0 pl-0">
                      <n-button
                        type="primary"
                        class="mt-0"
                        outline
                        round
                        @click.native="agregar(item, cantidad)"
                      >
                        Al carrito
                        <i class="fas fa-shopping-cart"></i>
                      </n-button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
    
              <div class="row vertical-center" v-if="item.description">
                <p class="mb-0 mr-5 text-default textJustify">
                {{ item.description }}</p>
              </div>

         
            </div>
            <template slot="footer"> </template>
          </card>
        </div>
      </div>
    </div>
    <!-- Modal Carrito-->
    <modal
      :show.sync="modals.carrito"
      v-on:keyup.esc="cerrarModales()"
      :show-close="false"
      class="modal-default"
      header-classes="justify-content-center"
      type="notice"
    >
      <div
        slot="header"
        class="modal-profile d-flex justify-content-center align-items-center"
      >
        <b-icon
          icon="check2-all"
          style="width: 100px; height: 100px"
          variant="success"
          aria-hidden="true"
        ></b-icon>
      </div>

      <h4>Se ha agregado a tu carrito correctamente</h4>

      <template slot="footer">
        <n-button type="info" link @click.native="cerrarModales()"
          ><b>Continuar comprando</b></n-button
        >
        <n-button type="primary" link @click.native="irACarrito()"
          ><b>Ver carrito</b></n-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
const PUBLIC_PATH = process.env.BASE_URL;
import { Button, FormGroupInput, Card, Modal, Badge } from "@/components";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "app",
  components: {
    Badge,
    Card,
    Carousel,
    Slide,
    [Button.name]: Button,

    [FormGroupInput.name]: FormGroupInput,
    [Modal.name]: Modal,
  },
  data() {
    return {
      profile: null,
      example: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      cantidad: 1,
      existencia: 0,
      item: {},
      modals: {
        carrito: false,
      },
      promotions: null,
    };
  },
  created() {
    this.profile = this.$decodeToken();
    this.getProduct();
    this.getPromotions();
  },
  methods: {
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },
    getProduct() {
      this.$api
        .get("/productos", {
          headers: { method: "singleItem" },
          params: { codigo: this.$route.params.id },
        })
        .then((response) => {
          if (response.data) {
            this.item = response.data.data[0];
            //this.item.images = this.item.images.split(",");
            this.example = this.item.images.split(",")[0];
          }
        })
        .catch((error) => {});
    },
    onClick(i) {
      this.example = i;
    },
    agregar(item, cantidad) {
      new Promise(async (reject, resolve) => {
        //1. Leer storage
        this.messageError = "";
        let carritoItems = this.$store.getters.getCarrito();

        //2.- Es item nuevo?
        let enCarrito = await carritoItems.filter(
          (e) => e.model == item.model
        );

        if (enCarrito.length > 0) {
          const index = carritoItems.indexOf(enCarrito[0]);
          if (index > -1) {
            carritoItems.splice(index, 1);
          }
          item.cantidad = cantidad + enCarrito[0].cantidad;
          carritoItems.push(item);
        } else {
          item.cantidad = cantidad;
          carritoItems.push(item);
        }

        //3. Actualizar carrito
        this.$store.commit("setCarrito", carritoItems);
        this.modals.detalles = false;
        this.modals.carrito = true;
      });
    },
    irACarrito() {
      this.$router.push({ name: "carrito" });
    },
    cerrarModales() {
      this.$router.push({ name: "productos" });
    },
  },
};
</script>

<style scoped>
.productsbar {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.productsimg {
  height: 150px;
  width: auto;
  margin: 30px 30px 30px 30px;
}

.image {
  width: 100%;
  height: auto;
  background-size: contain;
  cursor: pointer;
  margin: 10px;
  border-radius: 3px;
}
.vertical-center {
  align-items: center;
  font-size: 12pt;
}
</style>