 <template>
  <div class="sectionMision section-about-us">
    <div class="container">
      <div class="separator separator-primary">
        <div class="row">
          <div class="col-md-12">
            <div>
              <div class="container">
                <h1 class="subtitleABV text-center">Siempre cerca de ti</h1>
                <h5 class="textJustify">
                  ABV es una empresa que brinda soluciones eléctricas, diversas
                  opciones de iluminación y equipo eléctrico. Somos una empresa
                  orgullosamente mexicana, con las mejores opciones para brindar
                  un servicio de calidad en cualquiera de nuestros productos.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-story-overview">
        <div class="row">
          <div class="col-md-6">
            <div>
              <div class="container">
                <h3 class="subtitleABV">MISIÓN</h3>
                <h5 class="textJustify">
                  Brindar tanto el material como el equipo eléctrico, además de
                  todo lo necesario para la iluminación de un establecimiento,
                  ofreciendo un servicio especializado que cubra las necesidades
                  de manera óptima, garantizando que se quedará con una solución
                  eficiente y amigable con el ambiente.
                </h5>
              </div>
            </div>
            <br /><br />
            <!-- Second image on the left side of the article -->
            <div
              class="image-container image-center"
              style="background-image: url('img/vision.png')"
            ></div>
          </div>
          <div class="caja col-md-6">
            <div class="ImagenVision1">
              <div
                class="image-container image-center"
                style="background-image: url('img/mision.png')"
              ></div>
              <br /><br />
              <div class="container">
                <h3 class="subtitleABV">VISIÓN</h3>
                <h5 class="textJustify">
                  Contribuir de forma eficiente al uso de equipos con energía
                  renovable usando nuevas tecnologías, equipos y materiales
                  innovadores y así mejorar la calidad de vida de nuestros
                  clientes.
                </h5>
              </div>
            </div>
            <div class="ImagenVision2">
              <div class="container">
                <h3 class="subtitleABV">VISIÓN</h3>
                <h5 class="textJustify">
                  Contribuir de forma eficiente al uso de equipos con energía
                  renovable usando nuevas tecnologías, equipos y materiales
                  innovadores y así mejorar la calidad de vida de nuestros
                  clientes.
                </h5>
              </div>
              <br /><br />
              <div
                class="image-container image-center"
                style="background-image: url('img/valores.png')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import Vue from "vue";

import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,

    Slide,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      image: [
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h54/h86/8820146405406/Images_Original_Home_Depot/TG4321R_left_image.jpg",
          nom: "TL12412C : Centro de Carga",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h34/h26/8820147159070/Images_Original_Home_Depot/THQL1120_right_image.jpg",
          nom: "TL24415C : Centro de Carga",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h34/h26/8820147159070/Images_Original_Home_Depot/THQL1120_right_image.jpg",
          nom: "TL24415C : Centro de Carga ",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/heb/h31/8902208716830/Images_Digital_Assets/TLM2412CCU_front.jpg",
          nom: "TLM612FCUD : CC 1F3H 125A 6C 22KA Puerta F",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h80/he4/8901777293342/Images_Digital_Assets/TLM612FCUD_front.jpg",
          nom: "TM12410C : Centro de Carga",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/h9c/h0f/8900864016414/Images_Digital_Assets/THQL21100_front.jpg",
          nom: "THQL21100 : Interruptor T Enchufable ",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/hb6/h93/8899798040606/Images_Digital_front/THQL2140_front.jpg",
          nom: "THQL2140 : Interruptor T Enchufable",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/ha6/hed/8899799679006/Images_Digital_front/THQP220_front.jpg",
          nom: "THQP220 : Interruptor T Enchufable",
        },
        {
          img:
            "https://ecis-ecatalog.s3.amazonaws.com/sys-master/ecimages/hb1/hf1/8900861984798/Images_Digital_Assets/THQB2120_top.jpg",
          nom: "THQB2120 : Interruptor T Atornillable",
        },
      ],
    };
  },
};
</script>
<style scoped>
.section-about-us {
  background-color: rgba(163, 161, 158, 0.1);
}
.producto {
  height: 200px;
}
</style>
