<template>
  <div>
    <div class="container" style="margin-top: 150px; min-height: 50vh">
      <template>
        <tabs
          type="primary"
          tabNavWrapperClasses="col-md-3"
          tabContentClasses="col-md-9"
          vertical
          square
          class="row"
        >
          <!-- AQUI INICIA LO DE LOS PEDIDOS -->
          <tab-pane>
            <span slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i> Pedidos
            </span>
            <div>
              <h6>
                Al dar clic en cualquier encabezado, los datos se acomodarán de
                forma descendente o ascendente
              </h6>
              <b-table
                show-empty
                small
                stacked="md"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                empty-text="Sin pedidos registrados"
              >
                <template v-slot:cell(registro)="row">
                  {{
                    row.item.registro.split("T")[0] +
                    " " +
                    row.item.registro.split("T")[1].split(".")[0]
                  }}
                </template>

                <template v-slot:cell(importe)="row">
                  {{ $custom.Formatter.format(row.item.importe) }}
                </template>

                <template v-slot:cell(pdf)="row">
                  <n-button v-if="row.item.url != null" size="sm">
                    <!--Descargar pdf-->
                    <i class="far fa-file-pdf"></i> PDF
                  </n-button>
                  <n-button v-if="row.item.url != null" size="sm">
                    <!--Descargar xml-->
                    <i class="far fa-file-excel"></i> XML
                  </n-button>
                </template>
                <template v-slot:cell(actions)="row">
                  <n-button
                    size="sm"
                    @click="row.toggleDetails"
                    :type="row.detailsShowing ? 'default' : 'primary'"
                  >
                    {{ row.detailsShowing ? "Ocultar" : "Detalles" }}
                  </n-button>
                </template>
                <template v-slot:row-details="row">
                  <card plain>
                    <h3>Estado del pedido:</h3>
                    <!------------------------------------------------------>
                    <stepper
                      :options="{
                        headers: [
                          { title: 'Pedido Recibido' },
                          { title: 'Pedidio en Prepraración' },
                          { title: 'Pedido Enviado' },
                          { title: 'Pedido Entregado' },
                        ],
                      }"
                      :currentPosition="
                        row.item.estatus == 'RECIBIDO'
                          ? 0
                          : row.item.estatus == 'ENVIADO'
                          ? 2
                          : row.item.estatus == 'ENTREGADO'
                          ? 3
                          : 1
                      "
                    >
                    </stepper>
                    <div class="row">
                      <div class="col-md-6">
                        <b-table
                          show-empty
                          small
                          stacked="md"
                          :items="row.item.carrito"
                          :fields="[
                            {
                              key: 'cantidad',
                              label: 'Cantidad',
                              sortable: true,
                              sortDirection: 'desc',
                            },
                            {
                              key: 'descripcioncorta',
                              label: 'Producto',
                              sortable: true,
                              sortDirection: 'desc',
                            },
                            {
                              key: 'precio',
                              label: 'Precio Unit',
                              sortable: true,
                              sortDirection: 'desc',
                            },
                            {
                              key: 'importe',
                              label: 'Importe',
                              sortable: true,
                              sortDirection: 'desc',
                            },
                          ]"
                          empty-text="Lista de árticulos no disponible"
                        >
                          <template v-slot:cell(precio)="article">
                            {{ $custom.Formatter.format(article.item.precio) }}
                          </template>
                          <template v-slot:cell(importe)="article">
                            {{ $custom.Formatter.format(article.item.importe) }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Subtotal: </label>
                              {{ $custom.Formatter.format(row.item.subtotal) }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Descuento: </label>
                              {{ $custom.Formatter.format(row.item.descuento) }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Total: </label>
                              {{ $custom.Formatter.format(row.item.importe) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div class="row">
                      <div class="col-md-6">
                        <h4 class="mb-1">Domicilio de entrega:</h4>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">CP: </label>
                              {{ row.item.entcp }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Estado: </label>
                              {{ row.item.entestado }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Ciudad: </label>
                              {{ row.item.entlocalidad }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Colonia: </label>
                              {{ row.item.entcolonia }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Dirección: </label>
                              {{ row.item.entcalle }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="row.item.factura == 'SI'">
                        <h4 class="mb-1">Facturación:</h4>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">RFC: </label>
                              {{ row.item.rfc }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">USO CFDI: </label>
                              {{ row.item.usocfdi }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">CP: </label>
                              {{ row.item.cp }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Estado: </label>
                              {{ row.item.estado }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Ciudad: </label>
                              {{ row.item.localidad }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Colonia: </label>
                              {{ row.item.colonia }}
                            </p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <p class="mb-0">
                              <label class="text-primary h6">Dirección: </label>
                              {{ row.item.calle }}
                              {{
                                row.item.noexterior
                                  ? " #" + row.item.noexterior
                                  : ""
                              }}
                              {{ row.item.nointerior }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </card>
                  <!------------------------------------------------------>
                </template>
              </b-table>
              <b-col sm="7" md="6" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </tab-pane>
          <!-------------------------------------------------------------------------->
          <tab-pane>
            <span slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i> Inicio
            </span>
            <div class="row">
              <div class="col-md-3 offset-md-6">
                <n-button
                  type="primary"
                  class="mt-0"
                  outline
                  round
                  @click.native="saveHomeImages"
                >
                  <i class="fas fa-cloud-upload-alt"></i>
                  Guardar cambios
                </n-button>
              </div>
              <div class="col-md-3 text-danger">
                {{ message }}
              </div>
            </div>
            <div class="row" v-for="(image, index) in home" :key="index">
              <div class="col-md-6">
                <img class="home-image" :src="image.img" />
              </div>
              <div class="col-md-6">
                <div class="home-image">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="{
                      maxFiles: 1,
                      acceptedFiles: ['image/*'],
                      dictDefaultMessage:
                        'Suelta aqui tus imagenes o da clic para cargarlas',
                      dictMaxFilesExceeded:
                        'Solo puedes cargar un archivo para remplazar el actual',
                      dictRemoveFile: 'Remover esta imagen',
                      uploadMultiple: false,
                      url: $api.defaults.baseURL,
                      addRemoveLinks: true,
                      thumbnailWidth: 200,
                      thumbnailMethod: 'contain',
                    }"
                    :duplicateCheck="true"
                    v-on:vdropzone-thumbnail="sendingEvent($event, image.id)"
                    v-on:vdropzone-removed-file="cancelEvent($event, image.id)"
                  ></vue-dropzone>
                </div>
              </div>
            </div>
          </tab-pane>
          <!-------------------------------------------------------------------------->
          <tab-pane>
            <span slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i> Categorias
            </span>
            <div class="row">
              <div class="col-md-3 offset-md-6">
                <n-button
                  type="primary"
                  class="mt-0"
                  outline
                  round
                  @click.native="saveCategoriesImages"
                >
                  <i class="fas fa-cloud-upload-alt"></i>
                  Guardar cambios
                </n-button>
              </div>
              <div class="col-md-3 text-danger">
                {{ message }}
              </div>
            </div>
            <div class="row" v-for="(image, index) in categories" :key="index">
              <div class="col-md-6">
                <card style="width: 20rem;">
                  <img slot="image" class="card-img-top" :src="image.image" />
                  <h4 class="card-title my-0 py-0">{{ image.category }}</h4>
                </card>
              </div>
              <div class="col-md-6">
                <div class="home-image">
                  <card style="width: 20rem">
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="{
                        maxFiles: 1,
                        acceptedFiles: ['image/*'],
                        dictDefaultMessage:
                          'Suelta aqui tus imagenes o da clic para cargarlas',
                        dictMaxFilesExceeded:
                          'Solo puedes cargar un archivo para remplazar el actual',
                        dictRemoveFile: 'Remover esta imagen',
                        uploadMultiple: false,
                        url: $api.defaults.baseURL,
                        addRemoveLinks: true,
                        thumbnailWidth: 200,
                        thumbnailMethod: 'contain',
                      }"
                      :duplicateCheck="true"
                      v-on:vdropzone-thumbnail="
                        loadCategoryEvent($event, index, image.category)
                      "
                      v-on:vdropzone-removed-file="
                        cancelCategoryEvent($event, index)
                      "
                    ></vue-dropzone>
                  </card>
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </template>
    </div>
  </div>
</template>

<script>
import {
  Tabs,
  TabPane,
  Card,
  Button,
  Modal,
  Pagination,
  FormGroupInput,
  Progress,
  Badge,
} from "@/components";

//import { DatePicker } from "element-ui";
import { BTable, BCard, BModal, BPagination, BCol, BRow } from "bootstrap-vue";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

//import VueTableDynamic from "vue-table-dynamic";
import Stepper from "../../components/Stepper/Stepper";

const PUBLIC_PATH = process.env.BASE_URL;

export default {
  name: "profile",
  bodyClass: "profile-page",
  created() {
    this.start();
    this.getImages();
    this.getCategoriesImages();
  },
  mounted() {},
  props: {
    datos: Array,
    display: Boolean,
  },
  components: {
    Tabs,
    //[DatePicker.name]: DatePicker,
    TabPane,
    Card,
    Badge,
    [Progress.name]: Progress,
    //UploadImage,
    // VueTableDynamic,
    BTable,
    BTable,
    BCard,
    BRow,
    BModal,
    BPagination,
    BCol,
    vueDropzone: vue2Dropzone,

    [FormGroupInput.name]: FormGroupInput,
    [Pagination.name]: Pagination,
    [Button.name]: Button,
    [Modal.name]: Modal,
    Stepper,
  },
  data: () => {
    return {
      file: null,

      items: [],
      totalRows: 0,
      fields: [
        {
          key: "folioweb",
          label: "# Pedido",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "registro",
          label: "Fecha de compra",
          sortDirection: "desc",
          sortable: true,
        },
        {
          key: "importe",
          label: "Total",
          sortable: true,
          class: "text-center",
        },
        {
          key: "estatus",
          label: "Status",
          sortable: true,
          class: "text-center",
        },

        { key: "pdf", label: "PDF Factura" },
        { key: "xml", label: "XML Factura" },

        { key: "actions", label: "Acciones" },
      ],

      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],

      sortDirection: "asc",

      empleado: {
        nombre: null,
        apellido: null,
      },
      home: [],
      homeImages: [null, null, null, null],

      categories: [],
      categoriesImages: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],

      message: null,
    };
  },
  methods: {
    start: function () {
      if (this.$decodeToken() == null) this.$router.push({ name: "login" });
      if (this.$decodeToken().rol == "cliente_ecommerce") {
        this.$router.push({ name: "cliente" });
      }

      this.empleado.nombre = this.$decodeToken().nombre;
      this.empleado.apellido = this.$decodeToken().apellido;

      this.$api
        .get("/ventas", { headers: { method: "listar" } })
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = this.items.length;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getImages: async function () {
      this.home = [];
      try {
        let response = await this.$api.get("/personalization/covers");
        this.home = response.data.data;
      } catch (error) {
        this.home = [];
      }
    },
    getCategoriesImages: async function () {
     this.categories = [];
      try {
        let response = await this.$api.get("/personalization/categories");
        this.categories = response.data.data;
      } catch (error) {
        this.categories = [];
      }
    },
    clearCategoriesImages: async function () {
      this.categories = [
        {
          category: "Arte",
          image: null,
        },
        {
          category: "Blocks",
          image: null,
        },
        {
          category: "Cuadernos",
          image: null,
        },
        {
          category: "",
          image: null,
        },
        {
          category: "Escolares",
          image: null,
        },
        {
          category: "Escritura",
          image: null,
        },
        {
          category: "Kits",
          image: null,
        },
        {
          category: "Libros",
          image: null,
        },
        {
          category: "Motolinea",
          image: null,
        },
        {
          category: "Oficina",
          image: null,
        },
        {
          category: "Papel",
          image: null,
        },
        {
          category: "Pegamento",
          image: null,
        },
      ];
    },
    sendingEvent: function (file, id) {
      this.homeImages[id - 1] = {
        id: id,
        data: file, //file.dataURL,
      };
    },
    cancelEvent: function (file, id) {
      this.homeImages[id - 1] = null;
    },
    saveHomeImages: async function () {
      var formData = new FormData();

      for (let index = 0; index < this.homeImages.length; index++) {
        const element = this.homeImages[index];
        if (element != null) {
          formData.append(element.id, element.data);
        }
      }
      try {
        let upload = await this.$api.post("/personalization/covers", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      
        if (!upload.data.success) {
          this.message = "No fue posible actualizar todas las imagenes";
        } else {
          this.message = null;
          this.home = [];
          this.homeImages = [null, null, null, null];
          this.getImages();
        }
      } catch (error) {

        this.message = "No fue posible actualizar todas las imagenes";
        this.getImages();
      }
    },
    loadCategoryEvent: function (file, id, category) {
      this.categoriesImages[id] = {
        id: id,
        category: category,
        data: file, //file.dataURL,
      };
  
    },
    cancelCategoryEvent: function (file, id) {
      this.categoriesImages[id] = null;
  
    },
    saveCategoriesImages: async function () {
     
      var formData = new FormData();

      for (let index = 0; index < this.categoriesImages.length; index++) {
        const element = this.categoriesImages[index];
        if (element != null) {
          formData.append(element.category, element.data);
        }
      }
      try {
        let upload = await this.$api.post(
          "/personalization/categories",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (!upload.data.success) {
          this.message = "No fue posible actualizar todas las imagenes";
        } else {
         
          this.message = null;
          this.categories = [];
          this.categoriesImages = [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ];

          this.clearCategoriesImages();
          
          this.getCategoriesImages();
        }
      } catch (error) {
     
        this.message = "No fue posible actualizar todas las imagenes";
        this.getCategoriesImages();
      }
    },
  },

  computed: {},
};
</script>
<style scoped>
.card {
  margin-top: 50px;
  margin-left: 20px;
}
.home-image {
  max-height: 180px;
  width: auto;
  padding: 15px 15px;
}
</style>
