<template>
  <div>
    <!-- ACTIONS -->
    <section id="actions" class="py-4 mb-4 bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <card>
              <h4 class="card-title ml-4">Cambiar contraseña</h4>
              <template>
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <fg-input
                        class="col-sm-12 col-12"
                        placeholder="Contraseña"
                        value="nueva contraseña"
                        v-model="nuevaContrasenia"
                        autocomplete="on"
                        type="password"
                      >
                      </fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 text-danger">{{ errorMsg }}</div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 text-primary">{{ successMsg }}</div>
                  </div>
                </form>
              </template>
              <template slot="footer">
                <div class="text-right">
                  <n-button
                    type="danger"
                    @click.native="nuevaContrasenia = null"
                    >Cancelar</n-button
                  >
                  <n-button
                    type="primary"
                    class="mr-4 ml-2"
                    @click.native="cambiarCont"
                    >Cambiar Contraseña</n-button
                  >
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </section>

    <!-- PROFILE -->
    <section id="profile">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <card v-if="profile.rol != 'admin_ecommerce'">
              <h4 class="card-title ml-4">Datos de contacto</h4>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6"
                        >Cliente #
                      </label>
                      {{ profile.id }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6"
                        >Nombre / Razón social:
                      </label>
                      {{ nombre }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">Teléfono: </label>
                      {{ cel }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label
                        class="text-primary h6 mr-2 mt-1"
                        style="vertical-align: top"
                        >Correo:
                      </label>
                      <label v-html="mail ? splitCorreo(mail) : ''"></label>
                    </p>
                  </div>
                </div>
              </div>
            </card>

            <card class="my-5" v-if="profile.rol != 'admin_ecommerce'">
              <h4 class="card-title ml-4">Datos de fiscales</h4>
              <div
                class="card-body"
                v-for="(n, index) in rfcs"
                :key="index"
                :value="n.id"
              >
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6"
                        >Nombre / Razón social:
                      </label>
                      {{ n.razon }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">RFC: </label>
                      {{ n.rfc }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">CP: </label>
                      {{ n.cp }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">ESTADO: </label>
                      {{ n.estado }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">MUNICIPIO: </label>
                      {{ n.municipio }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">COLONIA: </label>
                      {{ n.colonia }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-0">
                      <label class="text-primary h6">DIRECCIÓN: </label>
                      {{ n.calle }} {{ n.ext ? "#" + n.ext : "" }}
                    </p>
                  </div>
                </div>
              </div>
            </card>

            <card v-if="profile.rol != 'admin_ecommerce'">
              <h4 class="card-title ml-4">
                <i class="fas fa-map-marker-alt"></i> Direcciones de envio
              </h4>
              <div class="card-body">
                <fg-select
                  v-model="domicilio"
                  label="Selecciona una dirección para ver los detalles "
                  :options="domicilios"
                  @change="seleccionarDireccion()"
                ></fg-select>
                <div v-if="domicilio != null">
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0">
                        <label class="text-primary h6">CP: </label>
                        {{ domicilio.cp }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0">
                        <label class="text-primary h6">ESTADO: </label>
                        {{ domicilio.estado }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0">
                        <label class="text-primary h6">MUNICIPIO: </label>
                        {{ domicilio.municipio }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0">
                        <label class="text-primary h6">COLONIA: </label>
                        {{ domicilio.colonia }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0">
                        <label class="text-primary h6">DIRECCIÓN: </label>
                        CALLE {{ domicilio.calle }} # {{ domicilio.ext }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  Card,
  Button,
  FormGroupInput,
  FormGroupSelect,
  FormGroupSelect as FgSelect,
  FormGroupInput as FgInput,
} from "@/components";

export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [FormGroupSelect.name]: FormGroupSelect,
    Card,
    FgInput,
    FgSelect,
  },
  data() {
    return {
      domicilio: null,
      errorMsg: "",
      successMsg: "",
      nuevaContrasenia: null,

      direccion: false,

      nombre: "",
      apellido: "",
      rfc: "",
      rs: "",
      cel: "",
      mail: "",
      profile: null,

      cp: "",

      mun: "",

      colonia: "",
      calle: "",

      ext: "",
      dir: null,

      colonias: [],
      domicilios: [],
      rfcs: [],
      reg: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$!%*(){};?&.-])([A-Za-z$@$!%*?(){};&.-]|[^ ]){8,15}$/,

      success: false,
      error: false,

      form: null,
    };
  },
  created() {
    this.profile = this.$decodeToken();

    if (this.profile == null) {
      this.$router.push({ name: "login" });
    } else if (this.profile.rol == "admin_ecommerce") {
      this.$router.push({ name: "dashboard" });
    } else {
      this.mail = this.profile.email;
      this.cel = this.profile.tel;
      this.nombre = this.profile.nombre;
      this.apellido = this.profile.apellido;
      this.getAddress();
      this.getRFC();
    }
  },
  methods: {
    resolveMsg: function () {
      setTimeout(() => {
        this.errorMsg = "";
        this.successMsg = "";
      }, 3000);
    },
    getRFC: function () {
      this.$api
        .post(
          "/infoUsuario",
          { id: this.$decodeToken().id },
          { headers: { method: "getRFC" } }
        )
        .then((response) => {
          this.rfcs = response.data.data;
        })
        .catch((error) => {});
    },
    getAddress: function () {
      this.$api
        .post(
          "/infoUsuario",
          { id: this.profile.id },
          { headers: { method: "infoUsuario" } }
        )
        .then((response) => {
          if (response.data) {
            this.domicilios = response.data.data;
          } else {
          }
        })
        .catch((error) => {});
    },
    ////////////////////////////////////////////
    cambiarCont: function () {
      if (this.reg.test(this.nuevaContrasenia)) {
        this.$api
          .post(
            "/cambiarCont",
            { password: this.nuevaContrasenia, id: this.$decodeToken().id },
            {
              headers: {
                method: "cambiarCont",
              },
            }
          )
          .then((response) => {
            this.successMsg = "Contraseña actualizada";
            this.resolveMsg();
          })
          .catch((error) => {
            this.errorMsg = "No se pudo cambiar la contraseña.";
            this.resolveMsg();
          });
      } else {
        this.errorMsg = "Contraseña no valida.";
        this.resolveMsg();
      }
    },
    /////////////////////////////////////////////////////////////
    splitCorreo(str) {
      return str.replaceAll(";", "<br/>");
    },
    async seleccionarDireccion() {
      let item = this.domicilios.filter((e) => e.value == this.domicilio);
      this.domicilio = item[0];
    },
  },
};
</script>
<style scoped>
.ini {
  width: 100%;
  height: 80px;
}

.img {
  height: 200px;
  width: 100%;
}
</style>