import axios from 'axios'
import store from '../store'

export default axios.create({
	baseURL: "https://api.abvelectricsupply.com:8001/",
	//baseURL: "http://api.localhost:8001/",
	headers: {
		Authorization: {
			toString() {
				return `Bearer ${store.getters.getToken()}`
			}
		},
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0'
	}
});
