<template>
  <div v-if="shoppingCart">
    <!-- No hay nada en el carrito-->
    <div v-if="shoppingCart.length < 1" style="min-height:41vh">
      <div class="d-flex justify-content-center my-3">
        <p class="h1 mb-2">
          <b-icon
            icon="exclamation-triangle-fill"
            style="width: 150px; height: 150px"
            variant="warning"
            aria-hidden="true"
          ></b-icon>
        </p>
      </div>
      <div class="d-flex justify-content-center my-3">
        <h1 class="primary--text text--primary">Carrito vacio</h1>
      </div>
      <div class="d-flex justify-content-center my-3">
        <n-button
          type="primary"
          class="mt-0"
          outline
          round
          @click.native="goToProducts"
        >
          Seguir comprando
        </n-button>
      </div>
    </div>
    <div v-else>
      <card
        plain
        v-for="(n, $index) in shoppingCart"
        :key="$index"
        cardBodyClasses="py-0 my-5"
      >
        <div class="container">
      
          <div class="row">
            <div class="col-md-2 pt-3">
              
              <img
                v-if="n.images == 'url' || n.images == null"
                class="producto"
                height="100px"
                width="100px"
                :src="noimage"
              />
              <img
                v-else
                class="producto"
                height="100px"
                width="100px"
                :src="n.images.split(',')[0]"
              />
            </div>
            <div class="col-md-6 pt-3">
              <router-link
                :to="{
                  name: 'details',
                  params: {
                    id: n.codigo,
                  },
                }"
                ><h5 class="card-title">{{ n.title }}</h5></router-link
              >
              <div class="card-body py-0">
                <div class="card-text mb-0">
                  <label>{{ $custom.Formatter.format(n.price) }}</label>
                </div>
            
                <a
                  :href="'productos?linea=' + n.line"
                  class="card-link text-success"
                >
                  <i class="fas fa-plus"></i> Más productos de esta categoría
                </a>
                <n-button
                  @click.native="borrar($index)"
                  class="card-link text-danger mb-0 mt-0"
                  type="neutral"
                >
                  <i class="fas fa-trash-alt"></i> Eliminar
                </n-button>
              </div>
            </div>
            <div class="col-md-2 pt-3">
              <fg-input
                v-model="n.cantidad"
                label="Cantidad"
                type="number"
                min="1"
                @change="getResume"
              ></fg-input>
            </div>
            <!--- RECALCULAR EL PRECIO POR LA CANTIDAD -->
            <div class="col-md-2 pt-3">
              <h2 class="h4">
                {{ $custom.Formatter.format(n.cantidad * n.price) }}
              </h2>
            </div>
          </div>
        </div>
      </card>
      <!------------------------------------------------------>
      <card plain>
        <div class="container">
          <div class="row">
            <div class="offset-md-8 col-md-2 mt-5 pt-1">
              <h4 class="card-subtitle mb-2 text-muted text-primary">
                <b>Subtotal :</b>
              </h4>
            </div>

            <div class="col-md-2 mt-5 pt-1 pb-2">
              <h4 class="card-subtitle mb-2 text-muted text-primary text-right">
                <b>{{ $custom.Formatter.format(totalfinal) }}</b>
              </h4>

              <div class="text-right">
                <n-button
                  round
                  class="btn btn-primary btn-round"
                  @click.native="continueAsUser()"
                >
                  Continuar
                </n-button>
              </div>
            </div>
          </div>
        </div>
      </card>

      <!------------------------------------------------------>

      <modal
        :show.sync="modals.message"
        :show-close="true"
        header-classes="justify-content-center"
      >
        <h4 class="text-center">
          {{ errorMsg }}
        </h4>
      </modal>
      <!------------------------------------------------------>
      <!--<card plain v-if="!profile">
        <div class="container">
          <div class="row justify-content-center">
            <!- -<div class="col-md-6 text-center">
              <div class="container">
                <card plain cardBodyClasses="pb-0">
                  <h5
                    class="card-title text-white"
                    style="background-color: gray"
                  >
                    Usuario Registrado
                  </h5>
                  <div class="row">
                    <div class="col-md-12">
                      <card type="login" plain>
                        <fg-input
                          v-model="usuario.usuario"
                          class="no-border input-lg"
                          addon-left-icon="now-ui-icons users_circle-08"
                          placeholder="No Socio"
                        ></fg-input>

                        <fg-input
                          v-if="!forgotpass"
                          v-model="usuario.password"
                          class="no-border input-lg"
                          addon-left-icon="now-ui-icons objects_key-25"
                          placeholder="Contraseña"
                          type="password"
                          autocomplete="on"
                        ></fg-input>

                        <template slot="raw-content">
                          <div class="card-footer text-center">
                            <n-button
                              v-if="forgotpass"
                              round
                              type="primary"
                              @click.native="forget()"
                            >
                              Recuperar contraseña
                            </n-button>

                            <n-button
                              v-else
                              round
                              type="primary"
                              @click.native="login()"
                            >
                              Iniciar sesión
                            </n-button>
                            <!- -*******************************- ->
                            <br />
                            <!- -*******************************- ->
                            <n-button
                              v-if="!forgotpass"
                              link
                              type="info"
                              class="mt-0 pt-0"
                              @click.native="forgotpass = true"
                            >
                              <b> Olvide mi contraseña</b>
                            </n-button>
                            <n-button
                              v-else
                              link
                              type="info"
                              class="mt-0 pt-0"
                              @click.native="forgotpass = false"
                            >
                              <b> volver</b>
                            </n-button>
                          </div>
                        </template>
                      </card>
                    </div>
                  </div>
                </card>
              </div>
            </div>-->

      <!------------------------------------------------------ >
            <div class="col-md-6 text-center">
              <div class="container">
                <card plain>
                  <!- <h5
                    class="card-title text-white"
                    style="background-color: gray"
                  >
                    Usuario Invitado
                  </h5>- ->
                  <template slot="raw-content">
                    <div class="card-footer text-center mt-0">
                      <n-button
                        round
                        class="menudos"
                        @click.native="continueAsGuest()"
                      >
                        Continuar
                      </n-button>
                    </div>
                  </template>- ->
                </card>
              </div>
            </div>
          </div>
        </div>
      </card>-->

      <!------------------------------------------------------>
      <Spiner :show="modals.loading" :msg="'Cargando...'" />
    </div>
  </div>
</template>

<script>
import {
  Button,
  FormGroupInput,
  Card,
  Badge,
  FormGroupInput as FgInput,
  NavLink,
  Modal,
} from "@/components";

import round from "vue-round-filter";
import { mask } from "vue-the-mask";
const PUBLIC_PATH = process.env.BASE_URL;
import Spiner from "../../components/Spiner/Spiner";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Card,
    Badge,
    FgInput,
    NavLink,
    [Modal.name]: Modal,
    Spiner,
  },
  directives: {
    mask,
  },
  filters: {
    round,
  },
  data() {
    return {
      noimage: `${PUBLIC_PATH}img/imagen_no_disponible.png`,
      modals: {
        message: false,
        loading: false,
      },
      shoppingCart: {},
      profile: null,
      timer: null,
      errorMsg: null,
      successMsg: null,

      subtotal: 0,
      descuento: 0,
      promocion: 0,
      iva: 0,
      totalfinal: 0,

      telefono: "###-###-####",
      usuario: {
        usuario: null,
        password: null,
      },
      nuevousuario: {
        password: null,
        confirmpassword: null,
        nombre: null,
        apellido: null,
        tel: null,
        email: null,
      },
      recuperacion: {
        email: null,
      },
      forgotpass: false,

      promotions: [],

      regMail: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
      regRFC: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
    };
  },
  async created() {
    this.shoppingCart = this.$store.getters.getCarrito();
    this.profile = this.$decodeToken();
    await this.getPromotions();
    this.getResume();
    this.timer = setInterval(() => {
      this.profile = this.$decodeToken();
    }, 500);
  },
  mounted() {
    this.usuario = {
      usuario: null,
      password: null,
    };
    this.nuevousuario = {
      password: null,
      confirmpassword: null,
      nombre: null,
      apellido: null,
      tel: null,
      email: null,
    };
    this.recuperacion = {
      email: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goToProducts() {
      this.$router.push({ name: "productos" });
    },
    //////////////////////////////////////////////////
    login: function () {
      this.setErrorMsg("");

      if (
        !this.regMail.test(this.usuario.usuario) &&
        this.usuario.usuario.length < 6
      ) {
        this.setErrorMsg("Debes ingresar tu número de cliente");
      } else if (this.usuario.password == "" || this.usuario.password == null) {
        this.setErrorMsg("Debes ingresar una contraseña");
      } else {
        this.$api
          .post("/login", this.usuario, { headers: { method: "enviarMail" } })
          .then((response) => {
            if (
              response.data.success &&
              response.data.data.rol == "cliente_ecommerce"
            ) {
              this.$store.commit("setToken", response.data.token);
              this.modals.loading = false;
            } else {
              this.setErrorMsg("Usuario no valido");
            }
          })
          .catch((error) => {
            this.setErrorMsg("Error al iniciar sesión");
          });
      }
    },
    forget: function () {
      this.setErrorMsg("");
      if (this.regMail.test(this.usuario.usuario)) {
        this.$api
          .post(
            "/forget",
            { id: this.usuario.usuario },
            { headers: { method: "enviarMail" } }
          )
          .then((response) => {
            this.setErrorMsg("Se envió a tu correo una contraseña temporal");
            this.forgotpass = false;
          });
      } else {
        this.setErrorMsg("Ingresa un correo válido");
      }
    },

    //////////////////////////////////////////////////
    setErrorMsg(msg) {
      if (msg == "") {
        this.errorMsg = msg;
        this.modals.loading = true;
        this.modals.message = false;
      } else {
        this.errorMsg = msg;
        this.modals.loading = false;
        this.modals.message = true;
        setTimeout(() => {
          this.errorMsg = "";
          this.modals.message = false;
        }, 3000);
      }
    },
    borrar(x) {
      var aux = this.$store.getters.getCarrito();
      aux.splice(x, 1);
      this.$store.commit("setCarrito", aux);
      this.shoppingCart = this.$store.getters.getCarrito();
      if (this.shoppingCart.length == 0) {
        this.$store.commit("setCarrito", []);
        this.$store.commit("setDomicilio", null);
        this.$store.commit("setEnvio", null);
        this.$store.commit("setFacturacion", null);
        this.$store.commit("setMSI", 1);
      }
      this.getResume();
    },
    continueAsGuest() {
      this.$store.commit("setFacturacion", null);
      this.$store.commit("setDomicilio", null);

      this.$router.push({ name: "envio" });
    },
    continueAsUser() {
      this.$router.push({ name: "envio" });
    },
    getResume() {
      this.subtotal = 0;
      this.descuento = 0;
      this.promocion = 0;
      this.iva = 0;
      for (var n in this.shoppingCart) {
        this.subtotal +=
          this.shoppingCart[n].price *
          this.shoppingCart[n].cantidad;
        
        
      }

      this.totalfinal =
        this.subtotal ;
    },
    //////////////////////////////////////////////////
    getPromotions: async function () {
      try {
        let response = await this.$api.get("/personalization/promotions");
        this.promotions = response.data.data;
      } catch (error) {
        this.promotions = [];
      }
    },
  },
  computed: {},
  watch: {
    // whenever question changes, this function will run
    profile: function () {
      this.getResume();
    },
  },
};
</script>
<style scoped>
.producto {
  float: right;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>