import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
    key: 'abv-app',
    storage: window.localStorage
})

const store = new Vuex.Store({
    state: {
        token: null,
        carrito: [],
        domicilio: null,
        facuracion: null,
        invitado: null,
        envio: null,
        msi: 1,
        total: 0,
        productos: [],
        transaction: null
    },
    mutations: {
        setToken(state, d) {
            state.token = d
        },
        setCarrito(state, d) {
            state.carrito = d
        },
        setDomicilio(state, d) {
            state.domicilio = d
        },
        setEnvio(state, d) {
            state.envio = d
        },
        setFacturacion(state, d) {
            state.facuracion = d
        },
        setInvitado(state, d) {
            state.invitado = d
        },
        setMSI(state, d) {
            state.msi = d
        },
        setTotal(state, d) {
            state.total = d
        },
        setProductos(state, d) {
            state.productos = d
        },
        setTransaction(state, d) {
            state.transaction = d
        }
    },
    getters: {
        getToken: state => cb => {
            return state.token
        },
        getCarrito: state => cb => {
            return state.carrito
        },
        getDomicilio: state => cb => {
            return state.domicilio
        },
        getEnvio: state => cb => {
            return state.envio
        },
        getFacturacion: state => cb => {
            return state.facuracion
        },
        getInvitado: state => cb => {
            return state.invitado
        },
        getMSI: state => cb => {
            return state.msi
        },
        getTotal: state => cb => {
            return state.total
        },
        getProductos: state => cb => {
            return state.productos
        },
        getTransaction: state => cb => {
            return state.transaction
        }
    },
    plugins: [vuexLocal.plugin]
})

export default store



